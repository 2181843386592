import styled from "styled-components";
import React, { FC, forwardRef } from "react";

const DropdownFieldContainer = styled.select`
	text-align: center;
	appearance: none;
	background-color: transparent;
	border: none;
	padding: 2px;
	margin: 0;
	width: 100%;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
	outline: none;

	::-ms-expand {
		display: none;
	}
`;

const OptionGroup = styled.optgroup``;
const Option = styled.option``;

interface DropdownFieldProps {
	style?: React.CSSProperties;
	onChange: (option: string) => void;
	value?: string;
	options:
		| { [department: string]: string }
		| { [category: string]: { [role: string]: string } | "-" };
}

const DropdownField: FC<DropdownFieldProps> = forwardRef(
	({ style, onChange, value, options }, ref) => {
		const handleDropdownChange = (
			event: React.ChangeEvent<HTMLSelectElement>
		) => onChange(event.target.value);

		const renderOptions = () =>
			options &&
			Object.keys(options).map((item) => {
				if (typeof options[item] === "string") {
					return (
						<Option value={item} key={item}>
							{options[item]}
						</Option>
					);
				}

				return (
					<OptionGroup label={item} key={item}>
						{Object.keys(options[item]).map((role) => (
							<Option value={role} key={role}>
								{options[item][role]}
							</Option>
						))}
					</OptionGroup>
				);
			});

		return (
			<DropdownFieldContainer
				innerRef={ref}
				onChange={handleDropdownChange}
				style={style}
				value={value}
			>
				{renderOptions()}
			</DropdownFieldContainer>
		);
	}
);

export default DropdownField;
