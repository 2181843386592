import styled from "styled-components";
import React, { FC, useState, useEffect, forwardRef } from "react";
import { blue, green, yellow } from "config/colors";

const StatusFieldContainer = styled.button`
	width: 100%;
	height: 100%;
	padding: 3px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
`;

const StatusDot = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${({ color }) => color || blue};
`;

interface StatusFieldProps {
	style?: React.CSSProperties;
	onChange: (newStatus: string) => void;
	status?: "enquired" | "confirmed" | "read";
}

const StatusField: FC<StatusFieldProps> = forwardRef(
	({ style, onChange, status = "enquired" }, ref) => {
		const [localStatus, setLocalStatus] = useState(status);

		useEffect(() => setLocalStatus(status), [status]);

		const handleStatusClick = () => {
			if (localStatus === "enquired") {
				setLocalStatus("read");
				onChange("read");
			}
			if (localStatus === "read") {
				setLocalStatus("confirmed");
				onChange("confirmed");
			}
			if (localStatus === "confirmed") {
				setLocalStatus("enquired");
				onChange("enquired");
			}
		};

		const getStatusDotColor = () => {
			if (localStatus === "confirmed") return green;
			if (localStatus === "read") return yellow;
			return blue;
		};

		return (
			<StatusFieldContainer
				innerRef={ref}
				onClick={handleStatusClick}
				style={style}
			>
				<StatusDot color={getStatusDotColor} />
			</StatusFieldContainer>
		);
	}
);

export default StatusField;
