import React from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { LocationBlockSettings } from "./LocationBlockSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

import { Location } from "components/pages/projects/LocationsPopup/types";

const LocationContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
`;

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	locations?: Location[];
	location1Label: string;
	location1Value: string;
	location2Label: string;
	location2Value: string;
	location1Address1Value: string;
	location1Address2Value: string;
	location1Address3Value: string;
	location2Address1Value: string;
	location2Address2Value: string;
	location2Address3Value: string;
	margin: [string, string, string, string];
};

export const LocationBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	locations = [
		{
			id: "0",
			formatted_address:
				"[Address Line 1], [Address Line 2], [Address Line 3], [Address Line 4]",
		},
		{
			id: "1",
			formatted_address:
				"[Address Line 1], [Address Line 2], [Address Line 3], [Address Line 4]",
		},
	],
	margin,
}: Partial<Text>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));

	const renderLocations = () => {
		if (locations) {
			return (
				<LocationContainer>
					{locations.map((location, locationNumber) => {
						var addressArray = [];

						if (location?.address_components !== undefined) {
							location.address_components.forEach((component) => {
								if (component.types.includes("street_number"))
									addressArray[0] = component.long_name;
								else if (component.types.includes("route"))
									addressArray[0] = `${addressArray[0]} ${component.long_name}`;
								else if (
									component.types.includes("neighborhood")
								)
									addressArray[1] = component.long_name;
								else if (
									component.types.includes("postal_town")
								)
									addressArray[2] = component.long_name;
								else if (
									component.types.includes(
										"administrative_area_level_2"
									) ||
									component.types.includes(
										"administrative_area_level_1"
									)
								) {
									addressArray[3] = component.long_name;
								} else if (
									component.types.includes("postal_code")
								)
									addressArray[4] = component.long_name;
							});
						} else {
							addressArray = location.formatted_address
								? location.formatted_address.split(",")
								: [];
						}

						return (
							<>
								<ColumnLayout key={locationNumber}>
									<Column justifyContent="flex-start">
										<ContentEditable
											innerRef={connect}
											html={`Location ${
												locationNumber + 1
											}`} // innerHTML of the editable div
											disabled
											onChange={(e) => {
												setProp(
													(prop) =>
														(prop.location1Label =
															e.target.value),
													500
												);
											}} // use true to disable editing
											tagName="h2" // Use a custom HTML tag (uses a div by default)
											style={{
												width: "100%",
												padding: "0 10px",
												color: `rgba(${Object.values(
													color
												)})`,
												fontSize: `${fontSize}px`,
												textShadow: `0px 0px 2px rgba(0,0,0,${
													(shadow || 0) / 100
												})`,
												margin: "0 0 15px 0",
												fontWeight,
												textAlign: "left",
											}}
										/>
									</Column>
									<Column justifyContent="flex-start">
										{addressArray.length
											? addressArray.map(
													(addressLine, key) => (
														<>
															{addressLine && (
																<ContentEditable
																	key={key}
																	innerRef={
																		connect
																	}
																	html={
																		addressLine
																	} // innerHTML of the editable div
																	disabled
																	onChange={(
																		e
																	) => {
																		setProp(
																			(
																				prop
																			) =>
																				(prop.location1Value =
																					e.target.value),
																			500
																		);
																	}} // use true to disable editing
																	tagName="h2" // Use a custom HTML tag (uses a div by default)
																	style={{
																		width: "100%",
																		padding:
																			"0 10px",
																		color: `rgba(${Object.values(
																			color
																		)})`,
																		fontSize: `${fontSize}px`,
																		textShadow: `0px 0px 2px rgba(0,0,0,${
																			(shadow ||
																				0) /
																			100
																		})`,
																		margin: "0 0 2px 0",
																		fontWeight,
																		textAlign:
																			"left",
																	}}
																/>
															)}
														</>
													)
											  )
											: null}
									</Column>
									<Column></Column>
								</ColumnLayout>
							</>
						);
					})}
				</LocationContainer>
			);
		}
	};
	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			{renderLocations()}
		</div>
	);
};

LocationBlock.craft = {
	displayName: "Location",
	props: {
		fontSize: "20",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 10, 10],
		shadow: 0,
		locations: [
			{
				id: "0",
				formatted_address:
					"[Address Line 1], [Address Line 2], [Address Line 3], [Address Line 4]",
			},
			{
				id: "1",
				formatted_address:
					"[Address Line 1], [Address Line 2], [Address Line 3], [Address Line 4]",
			},
		],
	},
	related: {
		toolbar: LocationBlockSettings,
	},
};
