import React from "react";
import { Element, Canvas, useEditor } from "@craftjs/core";
import { Tooltip } from "@mui/material";
import { Container } from "../../selectors/Container";
import {
	Text,
	TitleBlock,
	SubtitleBlock,
	ClientBlock,
	ProductionBlock,
	ScheduleBlock,
	LocationBlock,
	WeatherBlock,
	CrewBlock,
	NoteBlock,
	Divider,
} from "../../selectors";
import { black45, white } from "config/colors";

import {
	CheckBoxOutlineBlankSharp,
	TextFieldsSharp,
	UndoSharp,
	RedoSharp,
	Title as TitleIcon,
	ClosedCaption,
	Group,
	Business,
	PinDrop,
	Wc,
	Note,
	Remove,
	Schedule,
	WbSunny,
} from "@mui/icons-material";

import styled from "styled-components";

const ToolboxDiv = styled.div<{ enabled: boolean }>`
	position: relative;
	left: 0;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 20px 18px;
	transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	width: 50px;
	background-color: ${white};
	width: ${(props) => (!props.enabled ? `0` : "")};
	opacity: ${(props) => (!props.enabled ? `0;` : "")};
`;

const ToolContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
`;

const ToolSeparator = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${black45};
	margin: 5px 0;
`;

const Item = styled.a<{ disabled?: boolean; move?: boolean }>`
	svg {
		width: 22px;
		height: 22px;
		fill: #707070;
	}
	${(props) =>
		props.move &&
		`
    cursor: move;
  `}
	${(props) =>
		props.disabled &&
		`
    opacity:0.5;
    cursor: not-allowed;
  `}
`;

export const Toolbox = () => {
	const {
		enabled,
		connectors: { create },
		actions,
		canUndo,
		canRedo,
	} = useEditor((state, query) => ({
		enabled: state.options.enabled,
		canUndo: query.history.canUndo(),
		canRedo: query.history.canRedo(),
	}));

	return (
		<ToolboxDiv enabled={enabled && enabled}>
			<div>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={TitleBlock} />)}
				>
					<Item move>
						<Tooltip title="Title" placement="right">
							<TitleIcon />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={SubtitleBlock} />)}
				>
					<Item move>
						<Tooltip title="Subtitle" placement="right">
							<ClosedCaption />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={ClientBlock} />)}
				>
					<Item move>
						<Tooltip title="Client" placement="right">
							<Group />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={ProductionBlock} />)}
				>
					<Item move>
						<Tooltip title="Production Company" placement="right">
							<Business />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={ScheduleBlock} />)}
				>
					<Item move>
						<Tooltip title="Schedule" placement="right">
							<Schedule />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={LocationBlock} />)}
				>
					<Item move>
						<Tooltip title="Location" placement="right">
							<PinDrop />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={WeatherBlock} />)}
				>
					<Item move>
						<Tooltip title="Weather" placement="right">
							<WbSunny />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={CrewBlock} />)}
				>
					<Item move>
						<Tooltip title="Crew" placement="right">
							<Wc />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={NoteBlock} />)}
				>
					<Item move>
						<Tooltip title="Note" placement="right">
							<Note />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) => create(ref, <Canvas is={Divider} />)}
				>
					<Item move>
						<Tooltip title="Note" placement="right">
							<Remove />
						</Tooltip>
					</Item>
				</ToolContainer>
			</div>
			<div>
				<ToolContainer>
					<Item
						disabled={!canUndo}
						onClick={() => actions.history.undo()}
					>
						<Tooltip title="Undo" placement="right">
							<UndoSharp />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer>
					<Item
						disabled={!canRedo}
						onClick={() => actions.history.redo()}
					>
						<Tooltip title="Redo" placement="right">
							<RedoSharp />
						</Tooltip>
					</Item>
				</ToolContainer>
			</div>
		</ToolboxDiv>
	);
};

// Tools for internal use
{
	/* <ToolContainer
					ref={(ref) =>
						create(
							ref,
							<Element
								canvas
								is={Container}
								background={{ r: 78, g: 78, b: 78, a: 1 }}
								color={{ r: 0, g: 0, b: 0, a: 1 }}
								height="300px"
								width="300px"
							></Element>
						)
					}
				>
					<Item move>
						<Tooltip title="Container" placement="right">
							<CheckBoxOutlineBlankSharp />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolContainer
					ref={(ref) =>
						create(
							ref,
							<Text
								fontSize="12"
								textAlign="left"
								text="Hi there"
							/>
						)
					}
				>
					<Item move>
						<Tooltip title="Text" placement="right">
							<TextFieldsSharp />
						</Tooltip>
					</Item>
				</ToolContainer>
				<ToolSeparator /> */
}
