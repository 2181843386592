import React from "react";
import { useNode, useEditor } from "@craftjs/core";
import { NoteBlockSettings } from "./NoteBlockSettings";
import ContentEditable from "react-contenteditable";
import { Column, ColumnLayout } from "../components";

export type NoteBlockProps = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	noteValue: string;
	margin: [string, string, string, string];
};

export const NoteBlock = ({
	fontSize,
	textAlign,
	fontWeight,
	color,
	shadow,
	margin,
	noteValue = "COVID-19: PLEASE ENSURE YOU HAVE READ THE APA COVID-19 SHOOTING GUIDELINES AND HAVE SIGNED THE DECLARATION",
}: Partial<NoteBlockProps>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));
	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column justifyContent="center">
					<ContentEditable
						innerRef={connect}
						html={noteValue} // innerHTML of the editable div
						onChange={(e) => {
							setProp(
								(prop) => (prop.noteValue = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							fontWeight,
							textAlign,
							whiteSpace: "normal",
						}}
					/>
				</Column>
			</ColumnLayout>
		</div>
	);
};

NoteBlock.craft = {
	displayName: "Note",
	props: {
		fontSize: "23",
		textAlign: "left",
		fontWeight: "500",
		color: { r: 248, g: 9, b: 9, a: 1 },
		margin: [10, 10, 10, 10],
		shadow: 0,
		noteValue:
			"COVID-19: PLEASE ENSURE YOU HAVE READ THE APA COVID-19 SHOOTING GUIDELINES AND HAVE SIGNED THE DECLARATION",
	},
	related: {
		toolbar: NoteBlockSettings,
	},
};
