import { useAuth } from "components/common/HOC/AuthProvider";
import React, { FC, useState, useEffect } from "react";
import Link from "next/link";
import lz from "lzutf8";
import Router, { useRouter } from "next/router";
import { useEditor } from "@craftjs/core";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import { InputBase, Tooltip } from "@mui/material";
import { ArrowBackIos, FormatStrikethroughOutlined } from "@mui/icons-material";
import { useCollection, useDocument, fuego } from "@nandorojo/swr-firestore";
import firebase from "firebase/app";

import {
	ProjectOverviewProps,
	ProjectDetailsProps,
} from "components/pages/projects/types";
import { OrgData } from "pages/profile";
import { Popup, TextField } from "components/common";
import { blue, black45, black65, green, white } from "config/colors";

const HeaderDiv = styled.div<{ enabled: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
	width: ${(props) => (props.enabled ? "100%" : "100%")};
	z-index: 99999;
	position: fixed;
	transform: translateX(-50%);
	left: 50%;

	background-color: ${black65};
	${(props) =>
		!props.enabled
			? `
    backdrop-filter: blur(12px);
    background: ${black45};
    color:#2d2d2d;
  `
			: ""};
`;

const BackButtonAndTitleContainer = styled.div`
	width: 50%;
	position: relative;
`;

const Btn = styled.a`
	display: flex;
	align-items: center;
	padding: 7px 15px 8px;
	border-radius: 3px;
	background-color: ${({ enabled }) => (enabled ? blue : green)};
	color: #fff;
	font-size: 13px;
	cursor: pointer;
	svg {
		margin-right: 6px;
		width: 12px;
		height: 12px;
		fill: #fff;
		opacity: 0.9;
	}
`;

const TitlePopupContainer = styled.div`
	padding: 1px 20px 20px;
`;

type FeatureAccess = {
	[feature: string]: boolean;
};

interface HeaderProps {
	previewMode?: boolean;
	previewDocID?: string;
	previewProjectID?: string;
	previewCurrentDay?: string;
}

export const Header: FC<HeaderProps> = ({
	previewMode,
	previewDocID,
	previewProjectID,
	previewCurrentDay,
}) => {
	const { user }: any = useAuth();
	const [headerTitle, setHeaderTitle] = useState("Untitled");
	const [projectImageURL, setProjectImageURL] = useState("");
	const [orgLogoURL, setOrgLogoURL] = useState("");
	const [localJson, setLocalJson] = useState();

	const [showTitlePopup, setShowTitlePopup] = useState(false);

	const router = useRouter();
	const routerQuery = router?.query?.id ? router.query.id.toString() : "";
	const projectID = previewProjectID || router.query.projectID;
	const currentDay = previewCurrentDay || router.query.day;

	const {
		enabled,
		query,
		actions: { setOptions, deserialize },
	} = useEditor((state) => ({
		enabled: state.options.enabled,
	}));

	const { data: projectOverview } = useDocument<ProjectOverviewProps>(
		`projects/${projectID}`,
		{
			listen: true,
			parseDates: ["startShootDate"],
		}
	);

	const clientName = projectOverview?.clientName;
	const projectName = projectOverview?.projectName;
	const startShootDate =
		projectOverview?.startShootDate &&
		new Date(projectOverview?.startShootDate);

	const currentShootDate = startShootDate
		? startShootDate.setDate(
				startShootDate.getDate() + parseInt(currentDay as string) - 1
		  )
		: "";
	const shootDate =
		currentShootDate &&
		new Date(currentShootDate).toLocaleString().split(",")[0];

	const shootISODate =
		currentShootDate &&
		new Date(currentShootDate).toISOString().split("T")[0];

	const { data: projectDetails } = useDocument<ProjectDetailsProps>(
		`projects/${projectID}/projectDetails/shootDates/${shootISODate}/info`,
		{ listen: true, parseDates: ["createdAt"] }
	);

	const clientContacts = projectDetails?.clientContacts;
	const crewContacts = projectDetails?.crewContacts;
	const talentContacts = projectDetails?.talentContacts;
	const supplierContacts = projectDetails?.supplierContacts;
	const teamContacts = projectDetails?.teamContacts;

	const department = projectDetails?.department;
	const projectHead = projectDetails?.projectHead;
	const director = projectDetails?.director;
	const locations = projectDetails?.locations;
	const schedule = projectDetails?.schedule;

	const { data: orgData } = useCollection<OrgData>(`organisations`, {
		listen: true,
		where: [
			[
				`participants.${user.id}`,
				"in",
				["super", "owner", "admin", "executive", "organiser"],
			],
		],
	});

	const orgID = orgData?.[0]?.id;
	const orgName = orgData?.[0]?.name || "";
	const orgAddress = orgData?.[0]?.addressComponents || [];
	const orgEmail = orgData?.[0]?.email || "";
	const orgPhone = orgData?.[0]?.phone || "";

	useEffect(() => {
		const storage = fuego.storage();
		const projectImageRef = storage
			.ref()
			.child(`projects/${projectID}/clientLogo`);

		projectImageRef
			.getDownloadURL()
			.then((url) => {
				var xhr = new XMLHttpRequest();
				xhr.responseType = "blob";
				xhr.onload = (event) => {
					var blob = xhr.response;
				};
				xhr.open("GET", url);
				xhr.send();

				url && setProjectImageURL(url);
			})
			.catch((error) => {});
	}, [projectID]);

	useEffect(() => {
		const storage = fuego.storage();
		const orgLogoRef = storage
			.ref()
			.child(`organisations/${orgID || user.id}/logo`);

		orgLogoRef
			.getDownloadURL()
			.then((url) => {
				var xhr = new XMLHttpRequest();
				xhr.responseType = "blob";
				xhr.onload = () => {
					var blob = xhr.response;
				};
				xhr.open("GET", url);
				xhr.send();

				url && setOrgLogoURL(url);
			})
			.catch((error) => {});
	}, [orgID]);

	useEffect(() => {
		if (previewDocID || routerQuery) {
			var docRef = fuego.db
				.collection(`documents`)
				.doc(previewDocID || routerQuery);
			docRef
				.get()
				.then((doc) => {
					if (doc.exists && doc.data().documentJSON) {
						//console.log("deserialise: ", doc.data().documentJSON);
						const json = lz.decompress(
							lz.decodeBase64(doc.data().documentJSON)
						);
						//	console.log("deserialise: ", json);
						setLocalJson(json);
						if (doc.data().title) {
							setHeaderTitle(doc.data().title);
						}
					}
				})

				.catch((error) => {
					//No doc
				});
		}
	}, [routerQuery, previewDocID]);

	useEffect(() => {
		// TODO could check for hasUpdates and cancel load
		if (localJson) {
			var nodesJSON = JSON.parse(localJson);
			Object.entries(nodesJSON).map((node: any) => {
				if (node && node[1]) {
					if (node[1].displayName === "Title") {
						if (projectName)
							node[1].props.projectName = projectName;
						if (projectImageURL)
							node[1].props.clientLogoURL = projectImageURL;
						if (orgLogoURL) node[1].props.orgLogoURL = orgLogoURL;
					} else if (node[1].displayName === "Subtitle") {
						if (shootDate) node[1].props.shootDate = shootDate;
					} else if (node[1].displayName === "Client") {
						if (clientName) node[1].props.clientValue = clientName;
						if (clientContacts)
							node[1].props.clientContacts = clientContacts;
					} else if (node[1].displayName === "Crew") {
						if (crewContacts)
							node[1].props.crewContacts = crewContacts;
					} else if (node[1].displayName === "Schedule") {
						if (schedule) node[1].props.schedule = schedule;
					} else if (node[1].displayName === "Production") {
						if (orgName) node[1].props.orgName = orgName;
						if (orgAddress) node[1].props.orgAddress = orgAddress;
						if (teamContacts)
							node[1].props.teamContacts = teamContacts;
					} else if (node[1].displayName === "Location") {
						if (locations) node[1].props.locations = locations;
					}
				}
			});
			deserialize(nodesJSON);
		}
	}, [
		localJson,
		projectName,
		projectImageURL,
		orgLogoURL,
		shootDate,
		clientName,
		clientContacts,
		crewContacts,
		schedule,
		orgName,
		orgAddress,
		locations,
	]);

	// useEffect(() => {
	// 	if (query) {
	// 		var nodesJSON = JSON.parse(query.serialize());

	// 		Object.entries(nodesJSON).map((node: any) => {
	// 			if (node && node[1]) {
	// 				if (node[1].displayName === "Title") {
	// 					if (projectName)
	// 						node[1].props.projectName = projectName;
	// 					if (projectImageURL)
	// 						node[1].props.clientLogoURL = projectImageURL;
	// 				} else if (node[1].displayName === "Subtitle") {
	// 					if (shootDate) node[1].props.shootDate = shootDate;
	// 				} else if (node[1].displayName === "Client") {
	// 					if (clientName) node[1].props.clientValue = clientName;
	// 					if (clientContacts)
	// 						node[1].props.clientContacts = clientContacts;
	// 				} else if (node[1].displayName === "Crew") {
	// 					if (crewContacts)
	// 						node[1].props.crewContacts = crewContacts;
	// 				} else if (node[1].displayName === "Weather") {
	// 					if (shootDate) node[1].props.shootDate = shootDate;
	// 				} else if (node[1].displayName === "Schedule") {
	// 					if (schedule) node[1].props.schedule = schedule;
	// 				}
	// 			}
	// 		});
	// 		deserialize(nodesJSON);
	// 	}
	// }, [projectName, projectImageURL, shootDate, schedule]);

	useEffect(() => {
		headerTitle && setHeaderTitle(headerTitle);
	}, [headerTitle]);

	useEffect(() => {
		if (routerQuery === "newDocument") setShowTitlePopup(true);
	}, [routerQuery]);

	const handleTitleKeyDown = (event) => {
		if (event.key === "Enter") {
			setShowTitlePopup(false);
		}
	};

	const checkAndPushRoute = (id) => {
		setOptions((options) => (options.enabled = !enabled));
		if (routerQuery !== id) {
			Router.push(`/documents/builder/${id}`);
		}
	};

	const onRightButtonPress = () => {
		if (enabled) {
			const json = query.serialize();
			const compressedJson = lz.encodeBase64(lz.compress(json));

			var newDocumentID = routerQuery;

			var documentData: any = {
				title: headerTitle,
				documentJSON: compressedJson,
				author: user.id,
				participants: { [user.id]: true },
				orgParticipants: { [orgID]: true },
				updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
			};

			if (!routerQuery || routerQuery === "newDocument") {
				newDocumentID = fuego.db.collection(`documents`).doc().id;
				documentData.createdAt =
					firebase.firestore.FieldValue.serverTimestamp();
				fuego.db
					.collection(`documents`)
					.doc(newDocumentID)
					.set(documentData);
				checkAndPushRoute(newDocumentID);
			} else {
				var docRef = fuego.db
					.collection(`documents`)
					.doc(newDocumentID);
				docRef
					.get()
					.then((doc) => {
						if (!doc.exists) {
							newDocumentID = fuego.db
								.collection(`documents`)
								.doc().id;
							documentData.createdAt =
								firebase.firestore.FieldValue.serverTimestamp();
							fuego.db
								.collection(`documents`)
								.doc(newDocumentID)
								.set(documentData);
						} else {
							fuego.db
								.collection(`documents`)
								.doc(newDocumentID)
								.update(documentData);
						}
					})
					.then(() => checkAndPushRoute(newDocumentID))
					.catch((error) => {
						//No doc
					});
			}
		} else {
			setOptions((options) => (options.enabled = !enabled));
		}
	};

	const handleHeaderChange = (event) => setHeaderTitle(event.target.value);

	const handleTitlePopupChange = (value) => setHeaderTitle(value);
	const handleShowTitlePopupClose = () => setShowTitlePopup(false);

	return (
		<>
			{!previewMode && (
				<HeaderDiv enabled={enabled}>
					<BackButtonAndTitleContainer>
						<Tooltip title="Exit builder" placement="right">
							<Link href="/documents">
								<IconButton
									aria-label="Exit builder"
									style={{
										paddingLeft: 18,
										paddingRight: 18,
									}}
									size="large"
								>
									<ArrowBackIos
										style={{
											marginRight: -10,
											color: white,
											width: "80%",
										}}
									/>
								</IconButton>
							</Link>
						</Tooltip>

						<InputBase
							style={{ marginLeft: 10, color: white }}
							inputProps={{ "aria-label": "Document title" }}
							value={headerTitle}
							onChange={handleHeaderChange}
						/>
					</BackButtonAndTitleContainer>
					<Btn enabled={enabled} onClick={onRightButtonPress}>
						{enabled ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 18 18"
							>
								<title>S Checkmark 18 N</title>
								<rect
									id="Canvas"
									fill="#ff13dc"
									opacity="0"
									width="18"
									height="18"
								/>
								<path d="M15.656,3.8625l-.7275-.5665a.5.5,0,0,0-.7.0875L7.411,12.1415,4.0875,8.8355a.5.5,0,0,0-.707,0L2.718,9.5a.5.5,0,0,0,0,.707l4.463,4.45a.5.5,0,0,0,.75-.0465L15.7435,4.564A.5.5,0,0,0,15.656,3.8625Z" />
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 18 18"
							>
								<defs></defs>
								<title>S Edit 18 N</title>
								<rect id="Canvas" opacity="0" />
								<path d="M16.7835,4.1,13.9,1.216a.60751.60751,0,0,0-.433-.1765H13.45a.6855.6855,0,0,0-.4635.203L2.542,11.686a.49494.49494,0,0,0-.1255.211L1.0275,16.55c-.057.1885.2295.4255.3915.4255a.12544.12544,0,0,0,.031-.0035c.138-.0315,3.933-1.172,4.6555-1.389a.486.486,0,0,0,.207-.1245L16.7565,5.014a.686.686,0,0,0,.2-.4415A.61049.61049,0,0,0,16.7835,4.1ZM5.7,14.658c-1.0805.3245-2.431.7325-3.3645,1.011L3.34,12.304Z" />
							</svg>
						)}
						{enabled ? "Finish Editing" : "Edit"}
					</Btn>
				</HeaderDiv>
			)}
			{showTitlePopup && (
				<Popup
					onClose={handleShowTitlePopupClose}
					title="Set document title"
				>
					<TitlePopupContainer>
						<TextField
							value={headerTitle}
							onChange={handleTitlePopupChange}
							hiddenBorder
							autoFocus
							selectOnFocus
							placeholder="Enter a title"
							onKeyDown={handleTitleKeyDown}
						/>
					</TitlePopupContainer>
				</Popup>
			)}
		</>
	);
};
