import React from "react";
import { useEditor } from "@craftjs/core";
import styled from "styled-components";
export * from "./ToolbarItem";
export * from "./ToolbarSection";
export * from "./ToolbarTextInput";
export * from "./ToolbarDropdown";

const Container = styled.div`
	padding: 1px 0;
	height: 100%;
`;

const ToolbarContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding: 2px 5px;
`;

const ToolbarInstructions = styled.h2`
	font-size: 11px;
`;

export const Toolbar = () => {
	const { active, related } = useEditor((state) => ({
		active: state.events.selected,
		related:
			state.events.selected && state.nodes[state.events.selected].related,
	}));

	return (
		<Container>
			{active && related.toolbar && React.createElement(related.toolbar)}
			{!active && (
				<ToolbarContainer>
					<ToolbarInstructions>
						Click on a component to start editing.
					</ToolbarInstructions>
				</ToolbarContainer>
			)}
		</Container>
	);
};
