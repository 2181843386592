import styled from "styled-components";
import React, { FC } from "react";
import { Paper } from "@mui/material";

import { TextField } from "components/common";

const PaperHeader = styled.h3`
	font-size: 18px;
	margin: 20px 7px 20px;
`;

interface MessageBoardProps {
	value?: string;
	onChange: (value: string) => void;
}

const MessageBoard: FC<MessageBoardProps> = ({ value, onChange }) => {
	return (
		<Paper
			style={{
				padding: "0 13px 20px",
				overflow: "hidden",
			}}
		>
			<PaperHeader>Message board</PaperHeader>
			<TextField
				value={value}
				onChange={onChange}
				multiline
				fontSize="15px"
				maxChar={3000}
				hiddenBorder
				placeholder="Write a message for your team"
			/>
		</Paper>
	);
};

export default MessageBoard;
