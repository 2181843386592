import React from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { DividerSettings } from "./DividerSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

const LogoImg = styled.img`
	max-width: 100%;
`;

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	titleText: string;
	projectName: string;
	margin: [string, string, string, string];
};

export const Divider = ({ color, margin }: Partial<Text>) => {
	const {
		connectors: { connect },
	} = useNode();
	return (
		<div
			ref={connect}
			style={{
				backgroundColor: `rgba(${Object.values(color)})`,
				height: 2,
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		/>
	);
};

Divider.craft = {
	displayName: "Divider",
	props: {
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [5, 10, 5, 10],
		shadow: 0,
	},
	related: {
		toolbar: DividerSettings,
	},
};
