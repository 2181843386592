import styled from "styled-components";
import React, { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

import { black45, black85, white } from "config/colors";

const Background = styled.div`
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: ${black85};
	opacity: 20%;
	z-index: 98;
`;

const PopupContainer = styled.div`
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	align-items: "center";
	max-width: 90vw;
	margin-left: 40px;
	padding: ${({ padding }) => (padding ? padding : "")};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${white};
	border-radius: 8px;
	z-index: 999;
	transition: all 0.5s ease;

	@media only screen and (max-width: 780px) {
		width: 80vw;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 14px 16px 14px 24px;
`;

const HeaderTitleAndActionContainer = styled.div`
	display: flex;
`;

const Title = styled.h4`
	display: flex;
	align-items: center;
	color: ${black85};
	font-size: 24px;
	font-weight: 400;
	margin: 0 10px 0 0;

	@media only screen and (max-width: 780px) {
		font-size: 5vw;
	}
`;

const CloseButton = styled.button`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	font-size: 20px;
	font-weight: 600;
	width: 44px;
	height: 44px;
	background-color: ${white};
	border-width: 0px;
	color: ${black45};

	&:hover {
		color: ${black85};
	}
`;

const ChildrenContainer = styled.div`
	max-height: calc(100vh - 200px);
	overflow: auto;
`;

interface PopupProps {
	title?: string;
	onClose: () => void;
	children?: React.ReactNode;
	padding?: string;
	actionDescription?: string;
	onActionButtonPress?: () => void;
	actionButtonIcon?: React.ReactNode;
	isActionButtonDisabled?: boolean;
	titleButton?: React.ReactNode;
}

const Popup: FC<PopupProps> = ({
	title,
	onClose,
	children,
	padding,
	actionDescription,
	onActionButtonPress,
	actionButtonIcon = <Add />,
	isActionButtonDisabled,
	titleButton,
}) => {
	return (
		<>
			<Background onClick={onClose} />
			<PopupContainer padding={padding}>
				<HeaderContainer>
					<HeaderTitleAndActionContainer>
						{title ? <Title>{title}</Title> : <div />}
						{actionDescription && (
							<Tooltip
								title={actionDescription}
								placement="right"
								aria-label={actionDescription}
								style={{ zIndex: 9999 }}
							>
								<IconButton
									aria-label={actionDescription}
									size="small"
									onClick={onActionButtonPress}
								>
									{actionButtonIcon}
								</IconButton>
							</Tooltip>
						)}
					</HeaderTitleAndActionContainer>
					<CloseButton onClick={onClose}>&#x2715;</CloseButton>
				</HeaderContainer>
				<ChildrenContainer>{children}</ChildrenContainer>
			</PopupContainer>
		</>
	);
};

export default Popup;
