import React, { useRef, useState, useEffect } from "react";
import { useNode } from "@craftjs/core";
import { A4PageContainerSettings } from "./A4PageContainerSettings";
import styled from "styled-components";
import { black45, white } from "config/colors";

const ContainerContainer = styled.div`
	width: 840px;
	min-height: 1188px;
	height: auto;
	position: relative;
`;

const ChildrenContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 840px;
	height: auto;
	min-height: 1188px;
	padding: 20px;
`;

const A4PageGuide = styled.div`
	width: 840px;
	height: 1188px;
	border-bottom: 1px dotted ${black45};
	background-color: ${white};
	z-index: 2;
`;

interface A4PageContainerProps {
	children?: any;
}

export const A4PageContainer = ({
	children,
}: Partial<A4PageContainerProps>) => {
	const containerRef = useRef(null);
	const {
		connectors: { connect },
	} = useNode();
	const [containerHeight, setContainerHeight] = useState(1188);

	useEffect(() => {
		if (containerRef && containerRef.current) {
			setContainerHeight(containerRef.current.offsetHeight || 1);
		}
	}, [children, containerRef]);

	const renderPageGuides = () => {
		const numberOfPageGuides = Math.ceil(containerHeight / 1188);

		return (
			<>
				{[...Array(numberOfPageGuides)].map((_, index) => {
					return <A4PageGuide key={index} />;
				})}
			</>
		);
	};

	return (
		<ContainerContainer ref={connect}>
			{renderPageGuides()}
			<ChildrenContainer ref={containerRef}>{children}</ChildrenContainer>
		</ContainerContainer>
	);
};

A4PageContainer.craft = {
	displayName: "A4 Page",
	events: {
		selected: false,
		hovered: false,
	},
	rules: {
		canDrag: () => false,
	},
	related: {
		toolbar: A4PageContainerSettings,
	},
};
