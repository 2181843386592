import * as React from "react";
import { UserComponent, useNode } from "@craftjs/core";

interface ColumnProps {
	alignItems?: string;
	justifyContent?: string;
}

const Column: UserComponent<ColumnProps> = ({
	children,
	alignItems = "center",
	justifyContent = "space-evenly",
}) => {
	const { connectors } = useNode();

	return (
		<div
			id="Column"
			ref={connectors ? connectors.connect : null}
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems,
				justifyContent,
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
			}}
		>
			{children}
		</div>
	);
};

Column.craft = {
	name: "Column",
};

export default Column;
