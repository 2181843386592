import React from "react";
import styled from "styled-components";
import { ExpandMore } from "@mui/icons-material";
import { black15 } from "config/colors";

const SidebarItemDiv = styled.div<{ visible?: boolean; height?: string }>`
	display: flex;
	flex-direction: column;
	height: ${(props) =>
		props.visible && props.height && props.height !== "full"
			? `${props.height}`
			: "auto"};
	flex: ${(props) =>
		props.visible && props.height && props.height == "full"
			? `1`
			: "unset"};
	color: #545454;
`;

const Chevron = styled.a<{ visible: boolean }>`
	display: flex;
	align-items: center;
	transform: rotate(${(props) => (props.visible ? 180 : 0)}deg);
	svg {
		width: 16px;
		height: 16px;
	}
`;

const HeaderDiv = styled.div`
	display: flex;
	cursor: pointer;
	width: 245px;
	justify-content: space-between;
	padding: 0 10px;
	border-bottom: 1px solid ${black15};
	color: #615c5c;
	svg {
		fill: #707070;
	}
`;

const HeaderTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const HeaderTitle = styled.h2`
	font-size: 12px;
`;

const ChildrenContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export type SidebarItem = {
	title: string;
	height?: string;
	icon?: string;
	visible?: boolean;
	onChange?: (bool: boolean) => void;
};

export const SidebarItem: React.FC<SidebarItem> = ({
	visible,
	icon,
	title,
	children,
	height,
	onChange,
}) => {
	return (
		<SidebarItemDiv visible={visible} height={height}>
			<HeaderDiv
				onClick={() => {
					if (onChange) onChange(!visible);
				}}
			>
				<HeaderTitleContainer>
					<div style={{ width: 16, marginRight: 10 }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 18 18"
						>
							<defs></defs>
							<title>S Edit 18 N</title>
							<rect id="Canvas" opacity="0" />
							<path d="M16.7835,4.1,13.9,1.216a.60751.60751,0,0,0-.433-.1765H13.45a.6855.6855,0,0,0-.4635.203L2.542,11.686a.49494.49494,0,0,0-.1255.211L1.0275,16.55c-.057.1885.2295.4255.3915.4255a.12544.12544,0,0,0,.031-.0035c.138-.0315,3.933-1.172,4.6555-1.389a.486.486,0,0,0,.207-.1245L16.7565,5.014a.686.686,0,0,0,.2-.4415A.61049.61049,0,0,0,16.7835,4.1ZM5.7,14.658c-1.0805.3245-2.431.7325-3.3645,1.011L3.34,12.304Z" />
						</svg>
					</div>
					<HeaderTitle>{title.toLocaleUpperCase()}</HeaderTitle>
				</HeaderTitleContainer>
				<Chevron visible={visible}>
					<ExpandMore />
				</Chevron>
			</HeaderDiv>
			{visible ? <ChildrenContainer>{children}</ChildrenContainer> : null}
		</SidebarItemDiv>
	);
};
