import styled from "styled-components";
import React, { useMemo, useState, useCallback } from "react";
import { Button } from "@mui/material";
import { Add, DragHandle, Delete } from "@mui/icons-material";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { useDraggableInPortal } from "utils/helpers";
import { googlePlacesAPILibraries } from "components/pages/profile/constants";
import { black15, black45, white, black15o20, red, blue } from "config/colors";
import { Location } from "./types";

const LocationsPopupContainer = styled.div`
	width: 80vw;
	padding: 0px 24px;
	overflow: auto;
`;

const CloseButton = styled.button`
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	padding: 1px 5px 1px;
	cursor: pointer;
	color: ${black45};
	border: 0px;
	background-color: transparent;

	&:hover {
		background-color: ${black15};
		border-radius: 50%;
		color: ${red};
	}
`;

const TableRow = styled.tr`
	&:hover {
		background-color: ${black15o20};
	}

	&:hover > :last-child {
		background-color: ${white};
	}

	&:hover ${CloseButton} {
		visibility: visible;
	}
`;

const LocationListContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: min-content auto;
	overflow: auto;
`;

const LocationItemContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const LocationNumber = styled.span`
	font-weight: 600;
	margin: 13px 15px 20px 10px;
`;
const LocationAddress = styled.span`
	width: 100%;
	padding: 6px;
	border: 1px solid ${black45};
	background-color: ${white};
	border-radius: 4px;
	margin-bottom: 6px;
	display: grid;
	grid-template-columns: auto min-content;

	:focus-within {
		border: 1px solid ${blue};
	}
`;

const TextInput = styled.input`
	cursor: text;
	font-size: 1rem;
	line-height: 1.4375em;
	padding: 4px 0 5px;
	border: 0;
	box-sizing: content-box;
	background: none;
	height: 1.4375em;
	margin: 0 0 0 5px;
	width: 100%;
	display: block;
	-webkit-tap-highlight-color: transparent;
	min-width: 0;
	animation-duration: 10ms;

	:focus {
		outline-width: 0;
	}
`;

const DropBin = styled.div`
	height: ${({ showDelete }) => (showDelete ? "auto" : "0px")};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 6px;
	border: ${({ showDelete }) =>
		showDelete ? `1px dashed ${red}` : `0px dashed ${red}`};
	background-color: ${({ isHoveringOver }) => (isHoveringOver ? red : white)};
	border-radius: 4px;
	margin-bottom: 6px;
`;

const HandleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const maxAddresses = 5;

interface LocationsPopupProps {
	locations?: Location[];
	onLocationChange: (any) => void;
}

function LocationsPopup({ locations, onLocationChange }: LocationsPopupProps) {
	const [isDragging, setIsDragging] = useState(false);
	const [addressAutocompleteRefs, setAddressAutocompleteRefs] = useState({});

	const renderDraggable = useDraggableInPortal();

	const onSetAddressAutocompleteLoad = useCallback(
		(id) => (ref) => {
			setAddressAutocompleteRefs((prevState) => ({
				...prevState,
				[id]: ref,
			}));
		},
		[setAddressAutocompleteRefs]
	);

	const onSetAddressChanged = useCallback(
		(id) => () => {
			if (addressAutocompleteRefs?.[id] !== null) {
				onOrgAddressChange(id, addressAutocompleteRefs[id].getPlace());
			}
		},
		[addressAutocompleteRefs]
	);

	const editedLocations = useCallback(
		(id, address) => {
			const locationIndex = locations.findIndex((item) => item.id === id);
			const newLocationsList = Array.from(locations);
			newLocationsList[locationIndex] = { id, ...address };
			return newLocationsList;
		},
		[locations]
	);

	const onOrgAddressChange = useCallback(
		(id, address) => {
			onLocationChange(editedLocations(id, address));
		},
		[onLocationChange, editedLocations]
	);

	const onSetAddressFieldChange = useCallback(
		(id) => (event) => {
			onLocationChange(
				editedLocations(id, {
					formatted_address: event.target.value,
				})
			);
		},
		[onLocationChange, editedLocations]
	);

	const onDragStart = useCallback(() => {
		setIsDragging(true);
	}, []);

	const onDragEnd = useCallback(
		(result) => {
			setIsDragging(false);

			if (!result.destination) {
				return;
			}

			if (
				result.destination.index === result.source.index &&
				result.destination.droppableId === result.source.droppableId
			) {
				return;
			}

			const newLocationList = Array.from(locations);
			const [removedLocationItems] = newLocationList.splice(
				result.source.index,
				1
			);

			if (result.destination.droppableId !== "delete") {
				newLocationList.splice(
					result.destination.index,
					0,
					removedLocationItems
				);
			}

			onLocationChange(newLocationList);
		},
		[locations, onLocationChange]
	);

	const maxLocationReached = useMemo(
		() => locations && locations.length >= maxAddresses,
		[locations]
	);

	const onAddRowButtonClick = () => {
		if (!maxLocationReached) {
			const newLocationList = Array.from(locations);
			const possibleIds = Array.from(
				{ length: maxAddresses },
				(v, i) => i
			);
			var newId = "0";

			if (!!newLocationList.length) {
				newLocationList.forEach((item) => {
					const indexOfId = possibleIds.indexOf(parseInt(item.id));
					if (indexOfId > -1) possibleIds.splice(indexOfId, 1);
				});
				newId = `${possibleIds[0]}`;
			}

			onLocationChange([
				...locations,
				{ id: newId, formatted_address: "" },
			]);
		}
	};

	const onDragUpdate = useCallback((result) => {
		console.log(result?.destination?.droppableId);
	}, []);

	const renderLocationItem = (item, index) => (
		<Draggable draggableId={item.id} index={index} key={item.id}>
			{renderDraggable((provided) => (
				<LocationAddress
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<Autocomplete
						onLoad={onSetAddressAutocompleteLoad(item.id)}
						onPlaceChanged={onSetAddressChanged(item.id)}
						types={[]}
						restrictions={{ country: "gb" }}
						fields={[
							"address_components",
							"formatted_address",
							"place_id",
						]}
					>
						<TextInput
							key={item.id}
							value={item?.formatted_address || ""}
							onChange={onSetAddressFieldChange(item.id)}
							type="text"
							placeholder="Enter address"
						/>
					</Autocomplete>
					<HandleContainer {...provided.dragHandleProps}>
						<DragHandle />
					</HandleContainer>
				</LocationAddress>
			))}
		</Draggable>
	);

	const renderLocationIndex = useCallback(() => {
		if (locations.length)
			return (
				<LocationItemContainer>
					{locations.map((item, index) => (
						<LocationNumber key={item.id}>
							{index + 1}
						</LocationNumber>
					))}
				</LocationItemContainer>
			);
	}, [locations]);

	const renderLocationList = useCallback(() => {
		if (locations.length)
			return (
				<Droppable droppableId="list">
					{(provided) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							<LocationItemContainer>
								{locations.map((item, index) =>
									renderLocationItem(item, index)
								)}
								{provided.placeholder}
							</LocationItemContainer>
						</div>
					)}
				</Droppable>
			);
	}, [locations]);

	return (
		<>
			<LocationsPopupContainer>
				<LoadScript
					googleMapsApiKey={
						process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY
					}
					libraries={googlePlacesAPILibraries}
				>
					<DragDropContext
						onDragEnd={onDragEnd}
						onDragUpdate={onDragUpdate}
						onDragStart={onDragStart}
					>
						<LocationListContainer>
							{renderLocationIndex()}
							{renderLocationList()}
						</LocationListContainer>

						<Droppable droppableId="delete">
							{(provided, snapshot) => (
								<DropBin
									ref={provided.innerRef}
									{...provided.droppableProps}
									showDelete={isDragging}
									isHoveringOver={snapshot.isDraggingOver}
								>
									{isDragging && (
										<Delete style={{ color: red }} />
									)}
									{provided.placeholder}
								</DropBin>
							)}
						</Droppable>
					</DragDropContext>

					<Button
						variant="contained"
						style={{
							width: "100%",
							height: 30,
							marginTop: 4,
							marginBottom: 15,
						}}
						onClick={onAddRowButtonClick}
						disabled={maxLocationReached}
					>
						{maxLocationReached ? (
							"Max 5 locations"
						) : (
							<Add style={{ color: white }} />
						)}
					</Button>
				</LoadScript>
			</LocationsPopupContainer>
		</>
	);
}

export default LocationsPopup;
