export const clientContactColumns = [
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Position",
		accessor: "position",
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Phone",
		accessor: "phone",
	},
];

export const crewContactColumns = [
	{
		Header: "Status",
		accessor: "status",
	},
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Department",
		accessor: "department",
	},
	{
		Header: "Role",
		accessor: "role",
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Phone",
		accessor: "phone",
	},
];

export const talentContactColumns = [
	{
		Header: "Image",
		accessor: "image",
	},
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Position",
		accessor: "position",
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Phone",
		accessor: "phone",
	},
];

export const supplierContactColumns = [
	{
		Header: "Status",
		accessor: "status",
	},
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Department",
		accessor: "department",
	},
	{
		Header: "City",
		accessor: "city",
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Phone",
		accessor: "phone",
	},
];

export const teamContactColumns = [
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Role",
		accessor: "teamRole",
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Phone",
		accessor: "phone",
	},
];

export const departments = {
	"-": "-",
	lighting: "Lighting",
	rigging: "Rigging",
	camera: "Camera",
	production: "Production",
	art: "Art",
	glam: "Glam",
	sound: "Sound",
	specialEffects: "Special Effects",
};

export const defaultRolePerDepartment = {
	"-": "-",
	lighting: "gaffer",
	rigging: "riggerGaffer",
	camera: "dop",
	production: "director",
	art: "artDirector",
	glam: "costumeDesigner",
	sound: "soundMixer",
	specialEffects: "sfxSupervisor",
};

export const roles = {
	"-": "-",
	Lighting: {
		gaffer: "Gaffer",
		riggingGaffer: "Rigging Gaffer",
		bestBoy: "Best Boy",
		consoleOperator: "Console Operator",
		electrician: "Electrician",
		practicalGaffer: "Practical Gaffer",
		practicalSpark: "Practical Spark",
		generatorOperator: "Generator Operator",
	},
	Rigging: {
		riggerGaffer: "Rigging Gaffer",
		advancedRigger: "Advanced Rigger",
		basicRigger: "Basic Rigger",
	},
	Camera: {
		dop: "Director of Photography",
		cameraOperator: "Camera Operator",
		stediCamOp: "Stedi-Cam Op",
		jibOperator: "Jib Operator",
		firstAC: "1st AC (Focus Puller)",
		secondAC: "2nd AC (Loader)",
		traine: "Traine",
		keyGrip: "Key Grip",
		grip: "Grip",
		dit: "DIT",
		seniorPlayBackOp: "Senior Play Back Op",
		playBackOp: "Play Back Op",
	},
	Production: {
		director: "Director",
		firstAssistantDirector: "1st Assistant Director",
		secondAssistantDirector: "2nd Assistant Director",
		thirdAssistantDirector: "3rd Assistant Director",
		productionRunner: "Production Runner/Assistant",
		scriptSupervisor: "Script Supervisor",
		castingDirector: "Casting Director",
		locationManager: "Location Manager",
		locationAssitant: "Location Assistant",
	},
	Art: {
		artDirector: "Art Director",
		astArtDirector: "Ast Art Director",
		productionDesigner: "Production Designer",
		masterProps: "Master Props",
		propsBuyer: "Props Buyer",
		props: "Props",
		propsAssistant: "Props Assistant",
		constructionManager: "Contruction Manager",
		scenicArtist: "Scenic Artist",
		masterPainter: "Master Painter",
		masterCarpenter: "Master Carpenter",
		masterPlaster: "Master Plaster",
		painter: "Painter",
		carpenter: "Carpenter",
		plater: "Plater",
		standyByConstruction: "Stand by Construction",
		stageHand: "Stage Hand",
		labourer: "Labourer",
		homeEconomics: "Home Economics",
		foodStylist: "Food Stylist",
	},
	Glam: {
		costumeDesigner: "Costume Designer",
		stylist: "Stylist",
		wardrodeBuyer: "Wardrobe Buyer",
		wardrobe: "Wardrobe",
		chiefMakeupArtist: "Chief Makeup Artist",
		makeupArtist: "Makeup Artist",
		chiefHairDesigner: "Chief Hair Designer",
		hairDresser: "Hairdresser",
	},
	Sound: {
		soundMixer: "Sound Mixer",
		boomOperator: "Boom Operator",
		soundMaintenance: "Sound Maintenance",
		soundAssistant: "Sound Assistant",
	},
	"Special Effects": {
		sfxSupervisor: "SFX Supervisor",
		srSFXTechnician: "Sr. SFX Technician",
		sfxTechnician: "SFX Technician",
		sfxAssistant: "SFX Assistant",
		modelAnimator: "Model Animator",
		modelAssistant: "Model Assistant",
		leadArmourer: "Lead Armourer",
		armourerTechnician: "Armourer Technician",
	},
};
