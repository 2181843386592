import React, { Fragment } from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { CrewBlockSettings } from "./CrewBlockSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

import { ProjectDetailsProps } from "components/pages/projects/types";
import {
	departments,
	roles,
} from "components/pages/projects/ContactsTable/constants";

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	crewLabel: string;
	crewContacts: ProjectDetailsProps["crewContacts"];
	departmentLabel1: string;
	departmentLabel2: string;
	roleLabel: string;
	roleValue: string;
	emailValue: string;
	phoneNumberValue: string;
	margin: [string, string, string, string];
};

export const CrewBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	crewLabel = "Crew",
	crewContacts = [
		{
			name: "[Name]",
			department: "[Department]",
			role: "[Role]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			name: "[Name]",
			department: "[Department]",
			role: "[Role]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			name: "[Name]",
			department: "[Department]",
			role: "[Role]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			name: "[Name]",
			department: "[Department]",
			role: "[Role]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
	],
	margin,
}: Partial<Text>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));

	const renderCrewContacts = () => {
		const groupedCrewByDepartment = crewContacts.reduce(function (r, a) {
			r[a.department] = r[a.department] || [];
			r[a.department].push(a);
			return r;
		}, Object.create(null));

		return Object.keys(groupedCrewByDepartment).map(
			(department, depIndex) => (
				<Fragment key={`${depIndex}-${department}`}>
					<ColumnLayout>
						<Column alignItems="left">
							<ContentEditable
								innerRef={connect}
								html={departments?.[department] || department} // innerHTML of the editable div
								disabled
								onChange={(e) => true} // use true to disable editing
								tagName="h2" // Use a custom HTML tag (uses a div by default)
								style={{
									width: "100%",
									padding: "0 10px",
									color: `rgba(${Object.values(color)})`,
									fontSize: `16px`,
									textShadow: `0px 0px 2px rgba(0,0,0,${
										(shadow || 0) / 100
									})`,
									fontWeight: "600",
									margin: "15px 0 7px 0",
									textAlign: "left",
								}}
							/>
						</Column>
					</ColumnLayout>
					{groupedCrewByDepartment[department].map(
						(member, memIndex) => (
							<ColumnLayout key={`${memIndex}-${member.name}`}>
								<Column alignItems="left">
									<ContentEditable
										innerRef={connect}
										html={
											roles?.[
												departments?.[department]
											]?.[member?.role] ||
											member.role ||
											"-"
										} // innerHTML of the editable div
										disabled
										onChange={(e) => true} // use true to disable editing
										tagName="h2" // Use a custom HTML tag (uses a div by default)
										style={{
											width: "100%",
											padding: "0 10px",
											color: `rgba(${Object.values(
												color
											)})`,
											fontSize: `14px`,
											textShadow: `0px 0px 2px rgba(0,0,0,${
												(shadow || 0) / 100
											})`,
											margin: 0,
											fontWeight,
											textAlign: "left",
										}}
									/>
								</Column>
								<Column>
									<ContentEditable
										innerRef={connect}
										html={member?.name || "-"} // innerHTML of the editable div
										disabled
										onChange={(e) => true} // use true to disable editing
										tagName="h2" // Use a custom HTML tag (uses a div by default)
										style={{
											width: "100%",
											padding: "0 10px",
											color: `rgba(${Object.values(
												color
											)})`,
											fontSize: `14px`,
											textShadow: `0px 0px 2px rgba(0,0,0,${
												(shadow || 0) / 100
											})`,
											margin: 0,
											fontWeight,
											textAlign: "left",
										}}
									/>
								</Column>
								<Column>
									<ContentEditable
										innerRef={connect}
										html={member?.phone || "-"} // innerHTML of the editable div
										disabled
										onChange={(e) => true} // use true to disable editing
										tagName="h2" // Use a custom HTML tag (uses a div by default)
										style={{
											width: "100%",
											padding: "0 10px",
											color: `rgba(${Object.values(
												color
											)})`,
											fontSize: `14px`,
											textShadow: `0px 0px 2px rgba(0,0,0,${
												(shadow || 0) / 100
											})`,
											margin: 0,
											fontWeight,
											textAlign: "left",
										}}
									/>
								</Column>
								<Column>
									<ContentEditable
										innerRef={connect}
										html={member?.email || "-"} // innerHTML of the editable div
										disabled
										onChange={(e) => true} // use true to disable editing
										tagName="h2" // Use a custom HTML tag (uses a div by default)
										style={{
											width: "100%",
											padding: "0 10px",
											color: `rgba(${Object.values(
												color
											)})`,
											fontSize: `14px`,
											textShadow: `0px 0px 2px rgba(0,0,0,${
												(shadow || 0) / 100
											})`,
											margin: 0,
											fontWeight,
											textAlign: "left",
										}}
									/>
								</Column>
							</ColumnLayout>
						)
					)}
				</Fragment>
			)
		);
	};

	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column>
					<ContentEditable
						innerRef={connect}
						html={crewLabel} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.crewLabel = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0 0 0 0",
							fontWeight,
							textAlign: "left",
						}}
					/>
				</Column>
				<Column />
				<Column />
			</ColumnLayout>
			{renderCrewContacts()}
		</div>
	);
};

CrewBlock.craft = {
	displayName: "Crew",
	props: {
		fontSize: "20",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 20, 10],
		shadow: 0,
		crewLabel: "Crew",
		departmentLabel1: "[Department]",
		departmentLabel2: "[Department]",
		roleLabel: "[Role/Title]",
		roleValue: "[Name]",
		emailValue: "[DirSvr] E:[Email]",
		phoneNumberValue: "P:[Number]",
	},
	related: {
		toolbar: CrewBlockSettings,
	},
};
