import React from "react";
import { useNode, useEditor } from "@craftjs/core";
import { WeatherBlockSettings } from "./WeatherBlockSettings";
import ContentEditable from "react-contenteditable";
import { Column, ColumnLayout } from "../components";

export type WeatherBlockProps = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	weatherLabel: string;
	shootDate: string;
	emptyWeatherMessage: string;
	margin: [string, string, string, string];
};

export const WeatherBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	margin,
	weatherLabel = "Weather",
	shootDate = "[Shoot date]",
	emptyWeatherMessage = "Weather forecast will automatically update closer to project start",
}: Partial<WeatherBlockProps>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));
	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column>
					<ContentEditable
						innerRef={connect}
						html={weatherLabel} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.weatherLabel = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `20px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0 0 10px 0",
							fontWeight,
							textAlign: "left",
						}}
					/>
				</Column>
				<Column />
				<Column />
			</ColumnLayout>
			<ColumnLayout>
				<Column justifyContent="center">
					<ContentEditable
						innerRef={connect}
						html={`${emptyWeatherMessage} shootDate: ${shootDate}`} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) =>
									(prop.emptyWeatherMessage = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							fontWeight,
							textAlign,
						}}
					/>
				</Column>
			</ColumnLayout>
		</div>
	);
};

WeatherBlock.craft = {
	displayName: "Weather",
	props: {
		fontSize: "23",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 10, 10],
		shadow: 0,
		weatherLabel: "Weather",
		shootDate: "[Shoot date]",
		emptyWeatherMessage:
			"Weather forecast will automatically update closer to project start",
	},
	related: {
		toolbar: WeatherBlockSettings,
	},
};
