import React from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { TitleBlockSettings } from "./TitleBlockSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

const LogoImg = styled.img`
	max-width: 100%;
	max-height: 120px;
`;

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	titleText: string;
	projectName: string;
	clientLogoURL: string;
	orgLogoURL: string;
	margin: [string, string, string, string];
};

export const TitleBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	titleText = "Call sheet",
	projectName = "Project name",
	clientLogoURL = "/images/SPRK.png",
	orgLogoURL = "/images/SPRK.png",
	margin,
}: Partial<Text>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));
	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column>
					<LogoImg src={clientLogoURL} alt="Client logo" />
				</Column>
				<Column>
					<ContentEditable
						innerRef={connect}
						html={titleText} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.titleText = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: 0,
							fontWeight,
							textAlign,
						}}
					/>
					<ContentEditable
						innerRef={connect}
						html={projectName} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.projectName = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: 0,
							fontWeight,
							textAlign,
						}}
					/>
				</Column>
				<Column>
					<LogoImg src={orgLogoURL} alt="Production company logo" />
				</Column>
			</ColumnLayout>
		</div>
	);
};

TitleBlock.craft = {
	displayName: "Title",
	props: {
		fontSize: "20",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 20, 10],
		shadow: 0,
		titleText: "Call sheet",
		projectName: "[Project name]",
		clientLogoUrl: "/images/SPRK.png",
	},
	related: {
		toolbar: TitleBlockSettings,
	},
};
