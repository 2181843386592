import React, { FC, memo, useRef, useState } from "react";
import { Chip, ClickAwayListener } from "@mui/material";
import MUIDatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { formatDate } from "utils/helpers";

interface DatePickerProps {
	preText?: string;
	value: string;
	onChange: (date: any) => void;
	disableToolbar?: boolean;
	autoOk?: boolean;
	variant?: "dialog" | "inline" | "static";
	format?: string;
	id?: string;
	label?: string;
	minDate?: Date;
	maxDate?: Date;
	minDateMessage?: string;
	maxDateMessage?: string;
}

const DatePicker: FC<DatePickerProps> = ({
	preText,
	value,
	onChange,
	...props
}) => {
	const rootRef = useRef();
	const [pickerOpen, setPickerOpen] = useState(false);

	const handleChipClick = () => setPickerOpen(!pickerOpen);

	const renderDateChip = (props: any) => (
		<Chip
			label={
				<span>
					<b>
						{preText} {formatDate(props.inputProps.value)}
					</b>
				</span>
			}
			onClick={handleChipClick}
			style={{ width: "100%" }}
			ref={props.inputRef}
		/>
	);

	const handleClickAway = () => setPickerOpen(false);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div>
					<MUIDatePicker
						{...props}
						value={value}
						onChange={onChange}
						renderInput={renderDateChip}
						open={pickerOpen}
						onAccept={() => setPickerOpen(false)}
						DialogProps={{
							onBackdropClick: () => setPickerOpen(false),
						}}
						PopperProps={{ style: { paddingTop: 10 } }}
					/>
				</div>
			</ClickAwayListener>
		</LocalizationProvider>
	);
};

export default memo(DatePicker);
