import React from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { ClientBlockSettings } from "./ClientBlockSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

import { ProjectDetailsProps } from "components/pages/projects/types";

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	clientContacts: ProjectDetailsProps["clientContacts"];
	clientLabel: string;
	clientValue: string;
	roleLabel: string;
	roleValue: string;
	emailValue: string;
	phoneNumberValue: string;
	margin: [string, string, string, string];
};

export const ClientBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	clientContacts = [
		{
			position: "[Position]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			position: "[Position]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			position: "[Position]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			position: "[Position]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
	],
	clientLabel = "Client",
	clientValue = "[Client]",
	margin,
}: Partial<Text>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));

	const renderContactItem = (value, key) => (
		<ContentEditable
			key={key}
			innerRef={connect}
			html={value} // innerHTML of the editable div
			disabled
			onChange={(e) => true} // use true to disable editing
			tagName="h2" // Use a custom HTML tag (uses a div by default)
			style={{
				width: "100%",
				padding: "0 10px",
				color: `rgba(${Object.values(color)})`,
				fontSize: `16px`,
				textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
				fontWeight: "600",
				margin: "0 0 5px 0",
				textAlign: "left",
			}}
		/>
	);

	const renderClientContacts = () => {
		const renderPositionColumn = () =>
			clientContacts.map((item, key) =>
				renderContactItem(item.position, key)
			);
		const renderNameColumn = () =>
			clientContacts.map((item, key) =>
				renderContactItem(item.name, key)
			);
		const renderEmailColumn = () =>
			clientContacts.map((item, key) =>
				renderContactItem(`E: ${item.email}`, key)
			);
		const renderPhoneColumn = () =>
			clientContacts.map((item, key) =>
				renderContactItem(`P: ${item.phone}`, key)
			);

		return (
			<>
				<Column alignItems="left">{renderPositionColumn()}</Column>
				<Column alignItems="left">{renderNameColumn()}</Column>
				<Column alignItems="left">{renderEmailColumn()}</Column>
				<Column alignItems="left">{renderPhoneColumn()}</Column>
			</>
		);
	};
	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column>
					<ContentEditable
						innerRef={connect}
						html={clientLabel} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.clientLabel = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0 0 15px 0",
							fontWeight,
							textAlign: "left",
						}}
					/>
				</Column>

				<Column>
					<ContentEditable
						innerRef={connect}
						html={clientValue} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.clientValue = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0 0 15px 0",
							fontWeight,
							textAlign: "left",
						}}
					/>
				</Column>

				<Column></Column>
			</ColumnLayout>
			<ColumnLayout>{renderClientContacts()}</ColumnLayout>
		</div>
	);
};

ClientBlock.craft = {
	displayName: "Client",
	props: {
		fontSize: "20",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 20, 10],
		shadow: 0,
		clientLabel: "Client",
		clientValue: "[Client]",
		clientContacts: [
			{
				position: "[Position]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
			{
				position: "[Position]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
			{
				position: "[Position]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
			{
				position: "[Position]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
		],
		projectName: "[Project name]",
	},
	related: {
		toolbar: ClientBlockSettings,
	},
};
