import styled from "styled-components";
import React, { useMemo, useState, useCallback } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import Router, { useRouter } from "next/router";
import { deleteDocument } from "@nandorojo/swr-firestore";

import { Divider } from "components/common";

import { black15, black45, white, black15o20, red, blue } from "config/colors";

const OptionsPopupContainer = styled.div`
	padding: 0 0 36px;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const CopyDataButtonContainer = styled.div`
	padding: 36px 54px;
`;

interface OptionsPopupProps {
	copyDataAcrossAllDays?: () => void;
	onOptionsPopupClose?: () => void;
}

function OptionsPopup({
	copyDataAcrossAllDays,
	onOptionsPopupClose,
}: OptionsPopupProps) {
	const router = useRouter();
	const projectID = router.query.id ? router.query.id.toString() : "";
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [copyDataDialogOpen, setCopyDataDialogOpen] = useState(false);

	const onCopyDataClick = () => setCopyDataDialogOpen(true);

	const handleCopyDataDialogClose = () => setCopyDataDialogOpen(false);

	const onCopyDataConfirmClick = () => {
		onOptionsPopupClose();
		copyDataAcrossAllDays();
	};

	const onDeleteClick = () => setDeleteDialogOpen(true);

	const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

	const onDeleteProjectConfirmClick = () => {
		setDeleteDialogOpen(false);
		deleteDocument(`projects/${projectID}`);
		Router.push(`/projects`);
	};

	return (
		<OptionsPopupContainer>
			<CopyDataButtonContainer>
				<Button
					variant="outlined"
					color="success"
					onClick={onCopyDataClick}
				>
					Copy data to all days
				</Button>
			</CopyDataButtonContainer>
			<Divider margin="0 0 36px" width="90%" />
			<Button variant="outlined" color="error" onClick={onDeleteClick}>
				Delete project
			</Button>
			<Dialog
				open={deleteDialogOpen}
				onClose={handleDeleteDialogClose}
				aria-labelledby="delete-project-dialog"
			>
				<DialogTitle id="delete-project-dialog">
					Delete project?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This project will disappear for all members of your
						organisation.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleDeleteDialogClose}>
						CANCEL
					</Button>
					<Button autoFocus onClick={onDeleteProjectConfirmClick}>
						DELETE
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={copyDataDialogOpen}
				onClose={handleCopyDataDialogClose}
				aria-labelledby="delete-project-dialog"
			>
				<DialogTitle id="delete-project-dialog">
					Copy data and overwrite existing?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Data will be copied across all days and will overwrite
						all existing data.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCopyDataDialogClose}>
						CANCEL
					</Button>
					<Button autoFocus onClick={onCopyDataConfirmClick}>
						COPY
					</Button>
				</DialogActions>
			</Dialog>
		</OptionsPopupContainer>
	);
}

export default OptionsPopup;
