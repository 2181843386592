import styled from "styled-components";
import React, { FC, useMemo, useState } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { useTable } from "react-table";

import { TextField } from "components/common";
import {
	black15,
	black45,
	black65,
	black85,
	blue10,
	white,
	black15o20,
	red,
} from "config/colors";

const SchedulerContainer = styled.div`
	width: 100%;
`;

const TableData = styled.td`
	font-size: 13px;
	margin: 0;
	padding: 3px;
	border-bottom: 1px solid ${black15};
	border-right: 1px solid ${black15};

	:nth-last-child(2) {
		border-right: 0;
	}
	:last-child {
		border-right: 0;
	}
`;

const TimeCell = styled.input``;

const CloseButton = styled.button`
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	padding: 2px 5px 0;
	cursor: pointer;
	color: ${black45};
	border: 0px;
	background-color: transparent;

	&:hover {
		background-color: ${black15};
		border-radius: 50%;
		color: ${red};
	}
`;

const TableRow = styled.tr`
	&:hover {
		background-color: ${black15o20};
	}

	&:hover ${CloseButton} {
		visibility: visible;
	}

	td:nth-child(1) {
		width: 75px;
	}
	td:nth-child(3) {
		width: 30px;
	}
`;

interface SchedulerProps {
	data: { time: string; activity: string }[];
	onCellChange: (
		column: "time" | "activity",
		row: string,
		text: string
	) => void;
	onAddRowButtonClick: () => void;
	onDeleteRowClick: (row: number) => void;
}

const Scheduler: FC<SchedulerProps> = ({
	data,
	onCellChange,
	onAddRowButtonClick,
	onDeleteRowClick,
}) => {
	const [queuedChange, setQueuedChange] = useState([]);
	const columns = useMemo(
		() => [
			{
				Header: "Time",
				accessor: "time",
			},
			{
				Header: "Activity",
				accessor: "activity",
			},
			{
				Header: "Delete",
				id: "delete",
				accessor: (str) => "delete",
			},
		],
		[]
	);

	const tableData = useMemo(() => data, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data: tableData });

	const onRowBlur = () => {
		if (queuedChange.length !== 0) {
			onCellChange(queuedChange[0], queuedChange[1], queuedChange[2]);
			setQueuedChange([]);
		}
	};

	return (
		<SchedulerContainer>
			<table
				{...getTableProps()}
				style={{ width: "100%", borderSpacing: 0 }}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps()}
									style={{ display: "none" }}
								>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<TableRow {...row.getRowProps()}>
								{row.cells.map((cell) => {
									const cellRow = cell.row.id;

									if (cell.column.Header === "Time") {
										var timeValue = cell.value;
										if (
											queuedChange.length &&
											queuedChange[0] === "time" &&
											queuedChange[1] === cellRow
										)
											timeValue = queuedChange[2];
										return (
											<TableData {...cell.getCellProps()}>
												<TextField
													type="time"
													value={timeValue}
													fontSize="13px"
													hiddenBorder
													padding="2px"
													onChange={(text) =>
														setQueuedChange([
															"time",
															cellRow,
															text,
														])
													}
													onBlur={onRowBlur}
												/>
											</TableData>
										);
									}
									if (cell.column.Header === "Activity") {
										return (
											<TableData {...cell.getCellProps()}>
												<TextField
													value={cell.value}
													fontSize="13px"
													hiddenBorder
													padding="2px"
													selectOnFocus
													onChange={(text) =>
														onCellChange(
															"activity",
															cellRow,
															text
														)
													}
													onBlur={onRowBlur}
												/>
											</TableData>
										);
									}
									return (
										<TableData {...cell.getCellProps()}>
											<CloseButton
												aria-label={`Delete row ${cell.row.index}`}
												onClick={() => {
													onDeleteRowClick(
														cell.row.index
													);
												}}
											>
												×
											</CloseButton>
										</TableData>
									);
								})}
							</TableRow>
						);
					})}
				</tbody>
			</table>
			<Button
				variant="contained"
				style={{
					width: "100%",
					height: 30,
					marginTop: 4,
					backgroundColor: black15,
				}}
				onClick={onAddRowButtonClick}
			>
				<Add style={{ color: black65 }} />
			</Button>
		</SchedulerContainer>
	);
};

export default Scheduler;
