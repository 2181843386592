import React from "react";
import { Grid, Slider, RadioGroup } from "@mui/material";
import styled from "styled-components";
import { useNode } from "@craftjs/core";
import { ToolbarTextInput } from "./ToolbarTextInput";
import { ToolbarDropdown } from "./ToolbarDropdown";
import { withStyles } from "@mui/styles";
const iOSBoxShadow =
	"0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const SliderStyled = withStyles({
	root: {
		color: "#3880ff",
		height: 2,
		padding: "5px 0",
		width: "100%",
	},
	thumb: {
		height: 14,
		width: 14,
		backgroundColor: "#fff",
		boxShadow: iOSBoxShadow,
		marginTop: -7,
		marginLeft: -7,
		"&:focus,&:hover,&$active": {
			boxShadow:
				"0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
			// Reset on touch devices, it doesn't add specificity
			"@media (hover: none)": {
				boxShadow: iOSBoxShadow,
			},
		},
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 11px)",
		top: -22,
		"& *": {
			background: "transparent",
			color: "#000",
		},
	},
	track: {
		height: 2,
	},
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: "#bfbfbf",
	},
	mark: {
		backgroundColor: "#bfbfbf",
		height: 8,
		width: 1,
		marginTop: -3,
	},
	markActive: {
		opacity: 1,
		backgroundColor: "currentColor",
	},
})(Slider);

const Label = styled.h4`
	font-size: 14px;
`;

export type ToolbarItem = {
	prefix?: string;
	label?: string;
	full?: boolean;
	propKey?: string;
	index?: number;
	children?: React.ReactNode;
	type: string;
	onChange?: (value: any) => any;
};
export const ToolbarItem = ({
	full = false,
	propKey,
	type,
	onChange,
	index,
	...props
}: ToolbarItem) => {
	const {
		actions: { setProp },
		propValue,
	} = useNode((node) => ({
		propValue: node.data.props[propKey],
	}));
	const value = Array.isArray(propValue) ? propValue[index] : propValue;

	return (
		<Grid item xs={full ? 12 : 6}>
			<div style={{ marginBottom: 2 }}>
				{["text", "color", "bg", "number"].includes(type) ? (
					<ToolbarTextInput
						{...props}
						type={type}
						value={value}
						onChange={(value) => {
							setProp((props: any) => {
								if (Array.isArray(propValue)) {
									props[propKey][index] = onChange
										? onChange(value)
										: value;
								} else {
									props[propKey] = onChange
										? onChange(value)
										: value;
								}
							}, 500);
						}}
					/>
				) : type == "slider" ? (
					<>
						{props.label ? (
							<Label className="text-sm text-light-gray-2">
								{props.label}
							</Label>
						) : null}
						<SliderStyled
							value={parseInt(value) || 0}
							onChange={
								((_, value: number) => {
									setProp((props: any) => {
										if (Array.isArray(propValue)) {
											props[propKey][index] = onChange
												? onChange(value)
												: value;
										} else {
											props[propKey] = onChange
												? onChange(value)
												: value;
										}
									}, 1000);
								}) as any
							}
						/>
					</>
				) : type == "radio" ? (
					<>
						{props.label ? (
							<Label className="text-sm text-light-gray-2">
								{props.label}
							</Label>
						) : null}
						<RadioGroup
							value={value || 0}
							onChange={(e) => {
								const value = e.target.value;
								setProp((props: any) => {
									props[propKey] = onChange
										? onChange(value)
										: value;
								});
							}}
						>
							{props.children}
						</RadioGroup>
					</>
				) : type == "select" ? (
					<ToolbarDropdown
						value={value || ""}
						onChange={(value) =>
							setProp(
								(props: any) =>
									(props[propKey] = onChange
										? onChange(value)
										: value)
							)
						}
						{...props}
					/>
				) : null}
			</div>
		</Grid>
	);
};
