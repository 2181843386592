import React, { useState, useEffect, FC } from "react";
import { Editor, Frame, Element } from "@craftjs/core";
import {
	A4PageContainer,
	Container,
	Text,
	TitleBlock,
	SubtitleBlock,
	ClientBlock,
	ProductionBlock,
	ScheduleBlock,
	LocationBlock,
	WeatherBlock,
	CrewBlock,
	NoteBlock,
	Divider,
} from "./components/selectors";
import { Viewport, RenderNode } from "./components/editor";

interface DocumentBuilderProps {
	previewMode?: boolean;
	previewDocID?: string;
	previewProjectID?: string;
	previewCurrentDay?: string;
}
const DocumentBuilder: FC<DocumentBuilderProps> = ({
	previewMode,
	previewDocID,
	previewProjectID,
	previewCurrentDay,
}) => {
	const [enabled] = useState(true);
	return (
		<Editor
			resolver={{
				A4PageContainer,
				Container,
				Text,
				TitleBlock,
				SubtitleBlock,
				ClientBlock,
				ProductionBlock,
				ScheduleBlock,
				LocationBlock,
				WeatherBlock,
				CrewBlock,
				NoteBlock,
				Divider,
			}}
			enabled={!previewMode && enabled}
			onRender={RenderNode(previewMode)}
		>
			<Viewport
				previewMode={previewMode}
				previewDocID={previewDocID}
				previewProjectID={previewProjectID}
				previewCurrentDay={previewCurrentDay}
			>
				<Frame>
					<Element canvas is={A4PageContainer}></Element>
				</Frame>
			</Viewport>
		</Editor>
	);
};

export default DocumentBuilder;
