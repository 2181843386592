import React, { FC } from "react";
import { fuego } from "@nandorojo/swr-firestore";
import firebase from "firebase/app";
import Router from "next/router";

import { useAuth } from "components/common/HOC/AuthProvider";
import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { randomColors } from "config/colors";

const getRandomColor = () =>
	randomColors[Math.floor(Math.random() * randomColors.length)];

export const onCreateNewProjectClick = (user) => {
	let newProjectId;
	const currentDateWithoutTime = new Date(new Date().setHours(0, 0, 0, 0));
	const currentStringDate = currentDateWithoutTime
		.toISOString()
		.split("T")[0];
	newProjectId = fuego.db.collection(`projects`).doc().id;
	fuego.db
		.collection(`projects`)
		.doc(newProjectId)
		.set({
			projectName: "Untitled Project",
			participants: { [user.id]: "write" },
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			createdBy: user.id,
			updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
			projectColor: getRandomColor(),
			deliveryDate: firebase.firestore.Timestamp.fromDate(
				currentDateWithoutTime
			),
			startShootDate: firebase.firestore.Timestamp.fromDate(
				currentDateWithoutTime
			),
			endShootDate: firebase.firestore.Timestamp.fromDate(
				new Date(currentDateWithoutTime.getTime() + 86400000)
			),
			actions: [
				{
					id: "createCallSheet",
					detail: "Create call sheet",
					isChecked: false,
					action: "direct",
					link: "/documents/builder/newDocument",
				},
				{
					id: "confirmCrew",
					detail: "{counter}/{totalCrew} | Confirm crew",
					isChecked: false,
					action: "direct",
					data: { counter: 1, totalCrew: 1 },
					link: `/projects/${newProjectId}`,
				},
				{
					id: "confirmLocations",
					detail: "{totalLocations} | Confirm locations",
					isChecked: false,
					action: "direct",
					data: { totalLocations: 0 },
					link: `/projects/${newProjectId}`,
				},
				{
					id: "confirmCatering",
					detail: "Confirm catering",
					isChecked: false,
					action: "none",
					link: `/projects/${newProjectId}`,
				},
				{
					id: "confirmDietary",
					detail: "{counter} | Confirm dietary",
					isChecked: false,
					data: { counter: 0 },
					action: "counter",
					link: `/projects/${newProjectId}`,
				},
				{
					id: "confirmSuppliers",
					detail: "{counter}/{totalSuppliers} | Confirm suppliers",
					isChecked: false,
					action: "direct",
					data: { counter: 0, totalSuppliers: 0 },
					link: `/projects/${newProjectId}`,
				},
				{
					id: "buildSchedule",
					detail: "Build schedule",
					isChecked: false,
					action: "direct",
					link: `/projects/${newProjectId}`,
				},
				{
					id: "sendCallSheet",
					detail: "Send call sheet",
					isChecked: false,
					action: "direct",
					link: `/projects/${newProjectId}`,
				},
				{
					id: "importTemplates",
					detail: "Import templates",
					isChecked: false,
					action: "direct",
					link: `/projects/${newProjectId}`,
				},
			],
		});

	fuego.db
		.collection(`projects`)
		.doc(newProjectId)
		.collection("projectDetails")
		.doc("shootDates")
		.collection(`${currentStringDate}`)
		.doc("info")
		.set({
			crewContacts: [
				{
					department: "-",
					email: user?.email || "-",
					name: user?.username || user.displayName || "-",
					phone: "-",
					role: "-",
					status: "confirmed",
				},
			],
		});

	Router.push(`/projects/${newProjectId}`);
};

const AddNewProjectButton: FC = () => {
	const { user }: any = useAuth();

	return (
		<Tooltip
			title="Add new project"
			placement="right"
			aria-label="Add new project"
		>
			<IconButton
				aria-label="Add new project"
				size="small"
				onClick={() => onCreateNewProjectClick(user)}
			>
				<Add />
			</IconButton>
		</Tooltip>
	);
};

export default AddNewProjectButton;
