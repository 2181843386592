import React from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { ScheduleBlockSettings } from "./ScheduleBlockSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	scheduleLabel: string;
	schedule?: { time: string; activity: string }[];
	timeLabel: string;
	timeValue: string;
	margin: [string, string, string, string];
};

export const ScheduleBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	scheduleLabel = "Schedule",
	schedule = [
		{ time: "[TIME]", activity: "[Department]" },
		{ time: "[TIME2]", activity: "[Department]" },
		{ time: "[TIME3]", activity: "[Department]" },
		{ time: "[TIME4]", activity: "[Department]" },
	],
	margin,
}: Partial<Text>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));

	const renderScheduleItem = (value, key) => (
		<ContentEditable
			key={key}
			innerRef={connect}
			html={value} // innerHTML of the editable div
			disabled
			onChange={(e) => true} // use true to disable editing
			tagName="h2" // Use a custom HTML tag (uses a div by default)
			style={{
				width: "100%",
				padding: "0 10px",
				color: `rgba(${Object.values(color)})`,
				fontSize: `16px`,
				textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
				fontWeight: "600",
				margin: "0 0 5px 0",
				textAlign: "left",
			}}
		/>
	);

	const renderSchedule = () => {
		if (schedule) {
			const renderTimeColumn = () =>
				schedule.map((item, key) => renderScheduleItem(item.time, key));
			const renderDepartmentColumn = () =>
				schedule.map((item, key) =>
					renderScheduleItem(item.activity, key)
				);
			return (
				<>
					<Column alignItems="middle">
						{renderDepartmentColumn()}
					</Column>
					<Column alignItems="middle">{renderTimeColumn()}</Column>
				</>
			);
		}
		return (
			<>
				<Column alignItems="middle"></Column>
				<Column alignItems="middle"></Column>
			</>
		);
	};
	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column>
					<ContentEditable
						innerRef={connect}
						html={scheduleLabel} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.scheduleLabel = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0 0 10px 0",
							fontWeight,
							textAlign: "left",
						}}
					/>
				</Column>
				<Column />
				<Column />
			</ColumnLayout>

			<ColumnLayout>
				<Column />
				{renderSchedule()}
				<Column />
			</ColumnLayout>
		</div>
	);
};

ScheduleBlock.craft = {
	displayName: "Schedule",
	props: {
		fontSize: "20",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 20, 10],
		shadow: 0,
		scheduleLabel: "Schedule",
		schedule: [
			{ time: "[TIME]", activity: "[Department]" },
			{ time: "[TIME2]", activity: "[Department]" },
			{ time: "[TIME3]", activity: "[Department]" },
			{ time: "[TIME4]", activity: "[Department]" },
		],
	},
	related: {
		toolbar: ScheduleBlockSettings,
	},
};
