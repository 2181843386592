import React, {
	FC,
	useState,
	useEffect,
	useCallback,
	useMemo,
	createContext,
} from "react";
import Link from "next/link";
import { fuego, useDocument, useCollection } from "@nandorojo/swr-firestore";
import firebase from "firebase/app";
import Router, { useRouter } from "next/router";
import { DropzoneDialog } from "react-mui-dropzone";
import { Alert } from "@mui/lab";
import {
	AppBar,
	Box,
	Button,
	Card,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	Slider,
	Snackbar,
	Tabs,
	Tab,
	TextField,
	Tooltip,
} from "@mui/material";
import {
	AddAPhoto,
	Cached,
	CloudDone,
	LocationOn,
	Description,
	MoreVert,
} from "@mui/icons-material";
import styled from "styled-components";
import { debounce } from "throttle-debounce";

import { useAuth } from "components/common/HOC/AuthProvider";
import Navigation from "components/common/Navigation";
import {
	CardList,
	DatePicker,
	TextField as CommonTextField,
	Popup,
} from "components/common";
import {
	Scheduler,
	ContactsTable,
	MessageBoard,
	LocationsPopup,
	DocumentsPopup,
	OptionsPopup,
} from "components/pages/projects";
import {
	clientContactColumns,
	crewContactColumns,
	talentContactColumns,
	supplierContactColumns,
	teamContactColumns,
} from "components/pages/projects/ContactsTable/constants";
import ActionsBlock from "components/pages/projects/ActionsBlock";
import { black15, black25, black45, blue, red } from "config/colors";
import { getRelativeTime } from "utils/helpers";
import {
	departments,
	roles,
	defaultRolePerDepartment,
} from "components/pages/projects/ContactsTable/constants";
import { formatDate } from "utils/helpers";
import { Location } from "components/pages/projects/LocationsPopup/types";
import {
	FeatureAccess,
	ProjectOverviewProps,
	ProjectDetailsProps,
	DocumentData,
} from "components/pages/projects/types";
import {
	ActionKeys,
	ActionProps,
	ActionValues,
} from "components/pages/projects/ActionsBlock/types";

const ProjectHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

const LastEditedText = styled.span`
	display: flex;
	align-items: center;
	color: ${black45};
`;

const SliderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DaySliderCurrentDetails = styled.div``;

const ProjectProfileContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;

	@media (max-width: 1210px) {
		flex-direction: column;
		justify-content: center;
	}
`;

const ProjectProfileDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 8px;
`;

const ProjectColorSelector = styled.input`
	width: 100%;
	height: 15px;
	position: absolute;
	bottom: -5px;
	left: 0;
	cursor: pointer;
	border: none;
	background-color: transparent;

	opacity: 0;
`;

const ProjectOverviewContainer = styled.div`
	min-height: 306px;
	display: flex;
`;

const ProjectDetailsContainer = styled.div`
	width: 100%;
	padding-right: 25px;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ProjectDetailsInputContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ProjectDetailsLinksContainer = styled.div``;

const ProjectDetailLink = styled.div`
	font-size: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;

const BottomSpacer = styled.div`
	height: 12px;
`;

const VerticalDivider = styled.div`
	width: 1px;
	min-height: 100%;
	background-color: ${black45};
`;

const ScheduleContainer = styled.div`
	width: 100%;
	padding-left: 25px;
`;

const ScheduleHeader = styled.h4`
	margin: 0 0 15px 0;
`;

const ProjectLogoContainer = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 4px;
	margin-right: 15px;
	display: flex;
	justify-content: center;
	& > button {
		opacity: ${({ imageExists }) => (imageExists ? 0 : 1)};
	}
	&:hover > img {
		opacity: 0.5;
	}
	&:hover > button {
		opacity: 1;
	}

	@media (max-width: 1210px) {
		margin-right: 0;
		margin-bottom: 15px;
	}
`;

const PaperHeader = styled.h3`
	font-size: 18px;
	margin: 20px 20px 10px;
`;

const DayAndRefBar = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export type ActionsContextProps = {
	updateActions?: (
		id: string,
		updateKey: Partial<ActionKeys>,
		updateValue: Partial<ActionValues> | "+1" | "-1"
	) => void;
	handleActionClick?: (id: string, interaction?: "+1" | "-1") => void;
};

export const ActionsContext = createContext({
	updateActions: (
		id: string,
		updateKey: Partial<ActionKeys>,
		updateValue: Partial<ActionValues> | "+1" | "-1"
	) => {},
	handleActionClick: (id: string, interaction?: "+1" | "-1") => {},
});

const Project: FC = () => {
	const { user }: any = useAuth();
	const router = useRouter();
	const projectID = router.query.id ? router.query.id.toString() : "";
	const currentDayQuery = router.query.day
		? parseInt(router.query.day as string)
		: 1;

	var editingStatusTimer = null;
	const [editingStatus, setEditingStatus] = useState("saved");
	const [projectImageUploading, setProjectImageUploading] = useState(false);
	const [projectImageURL, setProjectImageURL] = useState("");
	const [showProjectImageDialog, setShowProjectImageDialog] = useState(false);
	const [logoUpdateToastOpen, setLogoUpdateToastOpen] = useState(false);
	const [visibleContactTab, setVisibleContactTab] = useState(0);
	const [showOptionsPopup, setShowOptionsPopup] = useState(false);
	const [showLocationPopup, setShowLocationPopup] = useState(false);
	const [showDocumentPopup, setShowDocumentPopup] = useState(false);
	const [highlightContactCard, setHighlightContactCard] = useState(false);
	const [highlightDetailsCard, setHighlightDetailsCard] = useState(false);

	const debounceDelay = 1000;

	const { data: projectOverview, set: setProjectOverviewData } =
		useDocument<ProjectOverviewProps>(`projects/${projectID}`, {
			listen: true,
			parseDates: [
				"deliveryDate",
				"startShootDate",
				"endShootDate",
				"updatedAt",
			],
		});

	const projectName = projectOverview?.projectName || null;
	const clientName = projectOverview?.clientName || null;
	const currentDateWithoutTime = new Date(new Date().setHours(0, 0, 0, 0));
	const deliveryDate =
		projectOverview?.deliveryDate || currentDateWithoutTime.toISOString();
	const startShootDate =
		projectOverview?.startShootDate || currentDateWithoutTime.toISOString();
	const endShootDate =
		projectOverview?.endShootDate ||
		new Date(currentDateWithoutTime.getTime() + 86400000).toISOString();
	const projectColor = projectOverview?.projectColor || "";
	const updatedAt = projectOverview?.updatedAt || "...";
	const messageBoard = projectOverview?.messageBoard || null;
	const selectedDocuments = projectOverview?.selectedDocuments || [];
	const actions = projectOverview?.actions || [];

	const [localUpdatedAt, setLocalUpdatedAt] = useState(
		getRelativeTime(projectOverview?.updatedAt, new Date())
	);
	const [localProjectName, setLocalProjectName] = useState(projectName);
	const [localClientName, setLocalClientName] = useState(clientName);
	const [localDeliveryDate, setLocalDeliveryDate] = useState(deliveryDate);
	const [localStartShootDate, setLocalStartShootDate] =
		useState(startShootDate);
	const [localEndShootDate, setLocalEndShootDate] = useState(endShootDate);
	const [currentDaySlider, setCurrentDaySlider] = useState(currentDayQuery);
	const [localProjectColor, setLocalProjectColor] = useState(projectColor);
	const [localMessageBoard, setLocalMessageBoard] = useState(messageBoard);
	const [localActions, setLocalActions] = useState(actions);
	const [localSelectedDocuments, setLocalSelectedDocuments] =
		useState(selectedDocuments);

	const startShootDateAsDate = new Date(startShootDate);
	const startShootDateAndCurrentDay =
		startShootDateAsDate &&
		new Date(startShootDateAsDate).setDate(
			startShootDateAsDate.getDate() + currentDaySlider - 1
		);
	const currentDate =
		startShootDateAndCurrentDay &&
		new Date(startShootDateAndCurrentDay).toISOString().split("T")[0];

	/** 				*/
	/** Project details */
	/** 				*/

	const { data: projectDetails, set: setProjectInfoData } =
		useDocument<ProjectDetailsProps>(
			`projects/${projectID}/projectDetails/shootDates/${currentDate}/info`,
			{ listen: true, parseDates: ["createdAt"] }
		);

	const shootDateTitle = projectDetails?.shootDateTitle || null;
	const department = projectDetails?.department || null;
	const projectHead = projectDetails?.projectHead || null;
	const director = projectDetails?.director || null;
	const locations = projectDetails?.locations || [];
	const schedule = projectDetails?.schedule || [];

	const clientContacts = projectDetails?.clientContacts || [];
	const crewContacts = projectDetails?.crewContacts || [];
	const talentContacts = projectDetails?.talentContacts || [];
	const supplierContacts = projectDetails?.supplierContacts || [];
	const teamContacts = projectDetails?.teamContacts || [];

	const [localShootDateTitle, setLocalShootDateTitle] =
		useState(shootDateTitle);
	const [localDepartment, setLocalDepartment] = useState(department);
	const [localProjectHead, setLocalProjectHead] = useState(projectHead);
	const [localDirector, setLocalDirector] = useState(director);
	const [localLocations, setLocalLocations] = useState(locations);
	const [localSchedule, setLocalSchedule] = useState(schedule);

	const [localClientContacts, setLocalClientContacts] =
		useState(clientContacts);
	const [localCrewContacts, setLocalCrewContacts] = useState(crewContacts);
	const [localTalentContacts, setLocalTalentContacts] =
		useState(talentContacts);
	const [localSupplierContacts, setLocalSupplierContacts] =
		useState(supplierContacts);
	const [localTeamContacts, setLocalTeamContacts] = useState(teamContacts);

	useEffect(() => {
		if (localProjectName === null) setLocalProjectName(projectName);
	}, [projectName]);

	useEffect(() => {
		if (localClientName === null) setLocalClientName(clientName);
	}, [clientName]);

	useEffect(() => {
		if (deliveryDate !== localDeliveryDate)
			setLocalDeliveryDate(deliveryDate);
	}, [deliveryDate]);

	useEffect(() => {
		if (deliveryDate !== localDeliveryDate)
			setLocalDeliveryDate(deliveryDate);
	}, [deliveryDate]);

	useEffect(() => {
		if (startShootDate != localStartShootDate)
			setLocalStartShootDate(startShootDate);
	}, [startShootDate]);

	useEffect(() => {
		if (endShootDate != localEndShootDate)
			setLocalEndShootDate(endShootDate);
	}, [endShootDate]);

	useEffect(() => {
		if (!localProjectColor) setLocalProjectColor(projectColor);
	}, [projectColor]);

	useEffect(() => {
		if (localMessageBoard === null) setLocalMessageBoard(messageBoard);
	}, [messageBoard]);

	useEffect(() => {
		if (localActions.length === 0 && actions.length !== 0)
			setLocalActions(actions);
	}, [actions]);

	useEffect(() => {
		if (!localSelectedDocuments)
			setLocalSelectedDocuments(selectedDocuments);
	}, [selectedDocuments]);

	useEffect(() => {
		if (localShootDateTitle === null)
			setLocalShootDateTitle(shootDateTitle);
	}, [shootDateTitle]);

	useEffect(() => {
		if (localDepartment === null) setLocalDepartment(department);
	}, [department]);

	useEffect(() => {
		if (localDirector === null) setLocalDirector(director);
	}, [director]);

	useEffect(() => {
		if (localLocations.length === 0 && locations.length !== 0)
			setLocalLocations(locations);
	}, [locations]);

	useEffect(() => {
		if (localSchedule.length === 0 && schedule.length !== 0)
			setLocalSchedule(schedule);
	}, [schedule]);

	useEffect(() => {
		if (localClientContacts.length === 0 && clientContacts.length !== 0)
			setLocalClientContacts(clientContacts);
	}, [clientContacts]);

	useEffect(() => {
		if (localCrewContacts.length === 0 && crewContacts.length !== 0)
			setLocalCrewContacts(crewContacts);
	}, [crewContacts]);

	useEffect(() => {
		if (localTalentContacts.length === 0 && talentContacts.length !== 0)
			setLocalTalentContacts(talentContacts);
	}, [talentContacts]);

	useEffect(() => {
		if (localSupplierContacts.length === 0 && supplierContacts.length !== 0)
			setLocalSupplierContacts(supplierContacts);
	}, [supplierContacts]);

	useEffect(() => {
		if (localTeamContacts.length === 0 && teamContacts.length !== 0)
			setLocalTeamContacts(teamContacts);
	}, [teamContacts]);

	useEffect(() => {
		setLocalShootDateTitle(shootDateTitle);
		setLocalDepartment(department);
		setLocalProjectHead(projectHead);
		setLocalDirector(director);
		setLocalLocations(locations);
		setLocalSchedule(schedule);
		setLocalClientContacts(clientContacts);
		setLocalCrewContacts(crewContacts);
		setLocalTalentContacts(talentContacts);
		setLocalSupplierContacts(supplierContacts);
		setLocalTeamContacts(teamContacts);

		if (projectID) {
			Router.push(
				{
					pathname: router.pathname,
					query: { id: projectID, day: currentDaySlider.toString() },
				},
				undefined,
				{ shallow: true }
			);
		}
	}, [currentDate]);

	useEffect(() => {
		const updatedInterval = setInterval(() => {
			const relativeTimeString = getRelativeTime(updatedAt, new Date());
			if (relativeTimeString !== "seconds ago" && relativeTimeString)
				setLocalUpdatedAt(relativeTimeString);
		}, 30000);

		const relativeTimeString = getRelativeTime(updatedAt, new Date());
		if (localUpdatedAt !== "seconds ago" && relativeTimeString)
			setLocalUpdatedAt(relativeTimeString);

		return () => clearInterval(updatedInterval);
	}, [updatedAt]);

	useEffect(() => () => clearTimeout(editingStatusTimer), []);

	const updateProjectOverview = useCallback((update) => {
		setEditingStatus("editing");
		updateProjectOverviewWithDebounce(update);
	}, []);

	const updateProjectOverviewWithDebounce = useCallback(
		debounce(debounceDelay, (update) => {
			setProjectOverviewData(
				{
					...update,
					updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
			setEditingStatus("justSaved");
			editingStatusTimer = setTimeout(
				() => setEditingStatus("saved"),
				2000
			);
		}),
		[]
	);

	const updateProjectDetails = useCallback(
		(update, merge = true) => {
			setEditingStatus("editing");
			updateProjectDetailsWithDebounce(update, merge);
		},
		[startShootDate, endShootDate, currentDayQuery]
	);

	const updateProjectDetailsWithDebounce = useCallback(
		debounce(debounceDelay, (update, merge = true) => {
			setProjectInfoData(update, { merge: merge });
			setProjectOverviewData(
				{
					updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
			setEditingStatus("justSaved");
			editingStatusTimer = setTimeout(
				() => setEditingStatus("saved"),
				2000
			);
		}),
		[startShootDate, endShootDate, currentDayQuery]
	);

	const copyDataAcrossAllDays = useCallback(() => {
		setEditingStatus("editing");
		var batch = fuego.db.batch();
		const cleanProjectDetails = { ...projectDetails };
		delete cleanProjectDetails.__snapshot;
		delete cleanProjectDetails.id;
		delete cleanProjectDetails.hasPendingWrites;

		for (
			var d = startShootDateAsDate;
			d <= new Date(endShootDate);
			d.setDate(d.getDate() + 1)
		) {
			var dataDateRef = fuego.db
				.collection("projects")
				.doc(projectID)
				.collection("projectDetails")
				.doc("shootDates")
				.collection(d.toISOString().split("T")[0])
				.doc("info");

			batch.set(dataDateRef, cleanProjectDetails, {
				merge: true,
			});
		}

		batch.commit().then(() => {
			setEditingStatus("saved");
		});
	}, [projectDetails, startShootDateAsDate, endShootDate]);

	const renderSavingText = () =>
		editingStatus === "editing" ? (
			<LastEditedText>
				Saving...
				<Cached fontSize="small" style={{ margin: "0 10px" }} />
			</LastEditedText>
		) : (
			<LastEditedText>
				{editingStatus === "justSaved" ? "Saved!" : ""}
				<Tooltip title="All changes saved!" placement="left">
					<CloudDone fontSize="small" style={{ margin: "0 10px" }} />
				</Tooltip>
			</LastEditedText>
		);

	/** Logo updates */
	useEffect(() => {
		const storage = fuego.storage();
		const projectImageRef = storage
			.ref()
			.child(`projects/${projectID}/clientLogo`);

		projectImageRef
			.getDownloadURL()
			.then((url) => {
				var xhr = new XMLHttpRequest();
				xhr.responseType = "blob";
				xhr.onload = (event) => {
					var blob = xhr.response;
				};
				xhr.open("GET", url);
				xhr.send();

				url && setProjectImageURL(url);
			})
			.catch((error) => {});
	}, [projectID]);

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const renderProjectImageUploadButton = () =>
		projectImageUploading ? (
			<CircularProgress />
		) : (
			<IconButton
				aria-label="Add a photo"
				style={{
					width: 100,
					height: 100,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: 2,
					border: `1px solid ${black25}`,
					position: "absolute",
				}}
				onClick={() => setShowProjectImageDialog(true)}
				size="large"
			>
				<AddAPhoto />
			</IconButton>
		);

	const onProjectImageUploaderSave = async (files) => {
		setProjectImageUploading(true);

		const newImage = files[0];

		const storage = fuego.storage();
		const metadata = {
			contentType: newImage.type,
		};
		const storageRef = await storage.ref();
		const imgFile = storageRef.child(`projects/${projectID}/clientLogo`);
		setShowProjectImageDialog(false);
		try {
			getBase64(newImage, (imageUrl) => {
				setProjectImageURL(imageUrl);
				setProjectImageUploading(false);
			});
			await imgFile.put(newImage, metadata);
			await imgFile
				.getDownloadURL()
				.then(async (url) => {
					var xhr = new XMLHttpRequest();
					xhr.responseType = "blob";
					xhr.onload = () => {
						var blob = xhr.response;
					};
					xhr.open("GET", url);
					xhr.send();

					if (url) {
						updateProjectOverview({ avatarImageURL: url });
					}
				})
				.catch((error) => {});
			setLogoUpdateToastOpen(true);
		} catch (e) {}
	};

	/** Project profile changes */
	const onProjectNameChange = (value) => {
		setLocalProjectName(value);
		updateProjectOverview({ projectName: value });
	};

	const onClientNameChange = (value) => {
		setLocalClientName(value);
		updateProjectOverview({ clientName: value });
	};

	const onProjectColorChange = (e) => {
		const color = e.target.value;
		setLocalProjectColor(color);
		updateProjectOverview({ projectColor: color });
	};

	/** Message board changes */

	const handleMessageBoardChange = (value) => {
		setLocalMessageBoard(value);
		updateProjectOverview({ messageBoard: value });
	};

	/** Project overview changes */
	const onShootDateTitleChange = (value) => {
		setLocalShootDateTitle(value);
		updateProjectDetails({ shootDateTitle: value });
	};

	const onDepartmentChange = (e) => {
		const departmentValue = e.target.value;
		setLocalDepartment(departmentValue);
		updateProjectDetails({ department: departmentValue });
	};

	const onProjectHeadChange = (e) => {
		const projectHeadValue = e.target.value;
		setLocalProjectHead(projectHeadValue);
		updateProjectDetails({ projectHead: projectHeadValue });
	};

	const onDirectorChange = (e) => {
		const directorValue = e.target.value;
		setLocalDirector(directorValue);
		updateProjectDetails({ director: directorValue });
	};

	const onLocationsChange = (value) => {
		updateActions("confirmLocations", "data", {
			totalLocations: value.length,
		});
		setLocalLocations(value);
		updateProjectDetails({ locations: value });
	};

	const onSelectedDocumentsChange = (selectedDocuments) => {
		setLocalSelectedDocuments(selectedDocuments);
		updateProjectOverview({ selectedDocuments: selectedDocuments });
	};

	const onDeliveryDateChange = (date) => {
		setLocalDeliveryDate(date);
		updateProjectOverview({ deliveryDate: date });
	};

	/** Toast closes */
	const handleLogoUpdateClose = () => setLogoUpdateToastOpen(false);

	/** Date and Ref control bar */

	const handleCurrentDateSlider = (e, newValue) => {
		if (typeof newValue === "number") {
			setCurrentDaySlider(newValue);
		}
	};

	const getDateSliderText = (value) => `Day ${value}`;

	const onStartShootDateChange = useCallback(
		(date) => {
			let endShootDate = localEndShootDate;
			const endShootDateTime = new Date(localEndShootDate).getTime();
			const newStartShootDateTime = new Date(date).getTime();
			const tenDaysInMs = 864000000;

			if (newStartShootDateTime > endShootDateTime) {
				const oldStartShootDateTime = new Date(
					localStartShootDate
				).getTime();
				const previousTimeDifference =
					endShootDateTime - oldStartShootDateTime;
				const newEndShootDateTime = new Date(
					newStartShootDateTime + previousTimeDifference
				).toISOString();

				endShootDate = newEndShootDateTime;
			} else if (endShootDateTime - newStartShootDateTime > tenDaysInMs) {
				const newEndShootDateTime = new Date(
					newStartShootDateTime + tenDaysInMs
				).toISOString();

				endShootDate = newEndShootDateTime;
			}

			if (endShootDate === localEndShootDate)
				updateProjectOverview({ startShootDate: date });
			else {
				updateProjectOverview({
					startShootDate: date,
					endShootDate: endShootDate,
				});
			}
			setLocalStartShootDate(date);
		},
		[localStartShootDate, localEndShootDate]
	);

	const onEndShootDateChange = (date) => {
		setLocalEndShootDate(date);
		updateProjectOverview({ endShootDate: date });
	};

	const getMaxShootDateSlider = () => {
		const parseEndDate =
			localEndShootDate?.seconds !== undefined
				? localEndShootDate.toDate()
				: localEndShootDate;
		return (
			Math.ceil(
				(Date.parse(parseEndDate) - Date.parse(localStartShootDate)) /
					(1000 * 60 * 60 * 24)
			) + 1 || 1
		);
	};

	const getMaxShootEndDate = () => {
		let beginningDate = new Date(localStartShootDate);
		return new Date(beginningDate.setDate(beginningDate.getDate() + 10));
	};

	const getDaySliderCurrentDetails = `${formatDate(
		startShootDateAndCurrentDay
	)}`;

	// const ShootDateValueLabelComponent = (props) => {
	// 	const { children, open, value } = props;
	// 	// Figure out zindex on tooltips or replace component
	// 	const isOpen =
	// 		showOptionsPopup ||
	// 		showLocationPopup ||
	// 		showDocumentPopup ||
	// 	return (
	// 		<StyledShootDateTooltip
	// 			open={open}
	// 			placement="top"
	// 			visibility={isOpen ? "hidden" : undefined}
	// 			title={`Day ${value}`}
	// 		>
	// 			{children}
	// 		</StyledShootDateTooltip>
	// 	);
	// };

	// For custom marks
	// const getShootDateSliderMarks = () =>
	// 	Array.from({ length: getMaxShootDateSlider() + 1 }, (_, i) => {
	// 		return { value: i, label: `Day ${i}` };
	// 	});

	const handleScheduleRowChange = (column, row, text) => {
		const otherColumn = column === "time" ? "activity" : "time";
		const newSchedule = Object.assign([], localSchedule, {
			[row]: {
				[otherColumn]: localSchedule[row][otherColumn],
				[column]: text,
			},
		});
		newSchedule.sort((a, b) => (a.time < b.time ? -1 : 1));
		setLocalSchedule(newSchedule);
		updateProjectDetails({ schedule: newSchedule });
	};

	const handleDeleteScheduleRow = (row) => {
		const newSchedule = localSchedule.filter((_, index) => index !== row);
		setLocalSchedule(newSchedule);
		updateProjectDetails({ schedule: newSchedule });
	};

	const handleAddScheduleRowClick = () => {
		var newTime = "06:00";
		if (localSchedule.length) {
			const latestTime = localSchedule[localSchedule.length - 1].time;

			newTime = `${`0${parseInt(latestTime.split(":")[0]) + 1}`.slice(
				-2
			)}:${latestTime.split(":")[1]}`;
		}
		const newSchedule = Object.assign([], localSchedule, {
			[localSchedule.length]: {
				time: newTime,
				activity: "activity",
			},
		});
		setLocalSchedule(newSchedule);
		updateProjectDetails({ schedule: newSchedule });
	};

	/** Actions */

	const updateActions = (
		id: string,
		updateKey: Partial<ActionKeys>,
		updateValue: Partial<ActionValues> | "+1" | "-1"
	) => {
		const newActions =
			localActions.length !== 0
				? localActions.map((action) => {
						if (action.id === id) {
							if (updateKey === "data") {
								if (updateValue === "+1") {
									return {
										...action,
										data: {
											...action?.data,
											counter:
												(action?.data?.counter || 0) +
												1,
										},
									};
								} else if (updateValue === "-1") {
									return {
										...action,
										data: {
											...action?.data,
											counter:
												(action?.data?.counter || 1) -
												1,
										},
									};
								} else if (typeof updateValue === "object") {
									return {
										...action,
										data: {
											...action?.data,
											...updateValue,
										},
									};
								}
							}
							return { ...action, [updateKey]: updateValue };
						} else return action;
				  })
				: [];
		setLocalActions(newActions);
		updateProjectOverview({ actions: newActions });
	};

	const handleActionClick = (id: string, interaction: "+1" | "-1") => {
		if (id === "createCallSheet") {
			Router.push("/documents/builder/newDocument");
		} else if (id === "confirmCrew") {
			onContactsTabChange(null, 1);
			setHighlightContactCard(true);
		} else if (id === "confirmLocations") {
			setShowLocationPopup(true);
		} else if (id === "confirmDietary") {
			updateActions(id, "data", interaction);
		} else if (id === "confirmSuppliers") {
			onContactsTabChange(null, 3);
			setHighlightContactCard(true);
		} else if (id === "buildSchedule") {
			setHighlightDetailsCard(true);
		} else if (id === "sendCallSheet") {
			setShowDocumentPopup(true);
		} else if (id === "importTemplates") {
			setShowDocumentPopup(true);
		}
	};

	/** Contacts */

	const onContactsTabChange = (_, tabNumber) =>
		setVisibleContactTab(tabNumber);

	const ContactTabPanel = useMemo(
		() => (props) => {
			const { children, value, index, ...other } = props;

			return (
				<div
					role="tabpanel"
					hidden={value !== index}
					id={`scrollable-auto-tabpanel-${index}`}
					aria-labelledby={`scrollable-auto-tab-${index}`}
					{...other}
				>
					{value === index && <div>{children}</div>}
				</div>
			);
		},
		[]
	);

	const handleClientContactRowChange = (column, row, text) => {
		const newContacts = Object.assign([], localClientContacts, {
			[row]: {
				...localClientContacts[row],
				[column]: text,
			},
		});
		setLocalClientContacts(newContacts);
		updateProjectDetails({ clientContacts: newContacts });
	};

	const handleAddClientContactRowClick = () => {
		const newContacts = Object.assign([], localClientContacts, {
			[localClientContacts ? localClientContacts.length : 0]: {
				name: "-",
				position: "-",
				email: "-",
				phone: "-",
			},
		});
		setLocalClientContacts(newContacts);
		updateProjectDetails({ clientContacts: newContacts });
	};

	const handleDeleteClientContactRow = (row) => {
		const newContacts = localClientContacts.filter(
			(_, index) => index !== row
		);

		setLocalClientContacts(newContacts);
		updateProjectDetails({ clientContacts: newContacts });
	};

	const handleCrewContactRowChange = (column, row, text) => {
		const newContacts = Object.assign([], localCrewContacts, {
			[row]: {
				...localCrewContacts[row],
				[column]: text,
			},
		});

		if (column === "status") {
			if (text === "confirmed")
				updateActions("confirmCrew", "data", "+1");
			else if (text === "enquired")
				updateActions("confirmCrew", "data", "-1");
		}

		if (column === "department") {
			if (text === "-") newContacts[row].role = "-";
			else newContacts[row].role = defaultRolePerDepartment[text];
		}

		if (column === "role" && localCrewContacts[row].department === "-") {
			const roleSectorKey = Object.keys(roles).find((department) =>
				Object.keys(roles[department]).find((role) => role === text)
			);
			const departmentKey = Object.keys(departments).find(
				(key) => departments[key] === roleSectorKey
			);

			newContacts[row].department = departmentKey;
		}

		setLocalCrewContacts(newContacts);
		updateProjectDetails({ crewContacts: newContacts });
	};

	const handleAddCrewContactRowClick = () => {
		updateActions("confirmCrew", "data", {
			totalCrew: localCrewContacts.length + 1,
		});

		const newContacts = Object.assign([], localCrewContacts, {
			[localCrewContacts.length]: {
				status: "enquired",
				name: "-",
				department: "-",
				role: "-",
				email: "-",
				phone: "-",
			},
		});

		setLocalCrewContacts(newContacts);
		updateProjectDetails({ crewContacts: newContacts });
	};

	const handleDeleteCrewContactRow = (row) => {
		updateActions("confirmCrew", "data", {
			totalCrew: localCrewContacts.length - 1,
		});

		const newContacts = localCrewContacts.filter(
			(_, index) => index !== row
		);

		setLocalCrewContacts(newContacts);
		updateProjectDetails({ crewContacts: newContacts });
	};

	const handleTalentContactRowChange = (column, row, text) => {
		const newContacts = Object.assign([], localTalentContacts, {
			[row]: {
				...localTalentContacts[row],
				[column]: text,
			},
		});
		setLocalTalentContacts(newContacts);
		updateProjectDetails({ talentContacts: newContacts });
	};

	const handleAddTalentContactRowClick = () => {
		const newContacts = Object.assign([], localTalentContacts, {
			[localTalentContacts.length]: {
				image: "",
				name: "-",
				position: "-",
				email: "-",
				phone: "-",
			},
		});
		setLocalTalentContacts(newContacts);
		updateProjectDetails({ talentContacts: newContacts });
	};

	const handleDeleteTalentContactRow = (row) => {
		const newContacts = localTalentContacts.filter(
			(_, index) => index !== row
		);

		setLocalTalentContacts(newContacts);
		updateProjectDetails({ talentContacts: newContacts });
	};

	const handleSupplierContactRowChange = (column, row, text) => {
		const newContacts = Object.assign([], localSupplierContacts, {
			[row]: {
				...localSupplierContacts[row],
				[column]: text,
			},
		});

		if (column === "status") {
			if (text === "confirmed")
				updateActions("confirmSuppliers", "data", "+1");
			else if (text === "enquired")
				updateActions("confirmSuppliers", "data", "-1");
		}

		setLocalSupplierContacts(newContacts);
		updateProjectDetails({ supplierContacts: newContacts });
	};

	const handleAddSupplierContactRowClick = () => {
		updateActions("confirmSuppliers", "data", {
			totalSuppliers: localSupplierContacts.length + 1,
		});

		const newContacts = Object.assign([], localSupplierContacts, {
			[localSupplierContacts.length]: {
				status: "enquired",
				name: "-",
				department: "-",
				city: "-",
				email: "-",
				phone: "-",
			},
		});
		setLocalSupplierContacts(newContacts);
		updateProjectDetails({ supplierContacts: newContacts });
	};

	const handleDeleteSupplierContactRow = (row) => {
		updateActions("confirmSuppliers", "data", {
			totalSuppliers: localSupplierContacts.length - 1,
		});

		const newContacts = localSupplierContacts.filter(
			(_, index) => index !== row
		);

		setLocalSupplierContacts(newContacts);
		updateProjectDetails({ supplierContacts: newContacts });
	};

	const handleTeamContactRowChange = (column, row, text) => {
		const newContacts = Object.assign([], localTeamContacts, {
			[row]: {
				...localTeamContacts[row],
				[column]: text,
			},
		});
		setLocalTeamContacts(newContacts);
		updateProjectDetails({ teamContacts: newContacts });
	};

	const handleAddTeamContactRowClick = () => {
		const newContacts = Object.assign([], localTeamContacts, {
			[localTeamContacts.length]: {
				name: "-",
				role: "-",
				email: "-",
				phone: "-",
			},
		});
		setLocalTeamContacts(newContacts);
		updateProjectDetails({ teamContacts: newContacts });
	};

	const handleDeleteTeamContactRow = (row) => {
		const newContacts = localTeamContacts.filter(
			(_, index) => index !== row
		);

		setLocalTeamContacts(newContacts);
		updateProjectDetails({ teamContacts: newContacts });
	};

	/** Popups */
	const onOptionsButtonPress = () => setShowOptionsPopup(true);
	const onOptionsPopupClose = () => setShowOptionsPopup(false);

	const onLocationButtonPress = () => setShowLocationPopup(true);
	const onLocationPopupClose = () => setShowLocationPopup(false);

	const onDocumentButtonPress = () => setShowDocumentPopup(true);
	const onDocumentPopupClose = () => setShowDocumentPopup(false);

	return (
		<Navigation
			breadCrumbItems={[
				"Home",
				"Projects",
				localProjectName !== null ? localProjectName : projectName,
			]}
			selectedKeys={["3"]}
		>
			<Box px={4} mb={3}>
				<ProjectHeader>
					<div />
					<LastEditedText>
						{renderSavingText()} {`Last edit was ${localUpdatedAt}`}
					</LastEditedText>
				</ProjectHeader>
				<Grid
					container
					spacing={3}
					alignItems="flex-start"
					justifyContent="space-between"
				>
					<Grid item sm={12} md={4}>
						<Card
							style={{
								minHeight: 160,
								padding: 30,
								marginBottom: 24,
								borderBottom: `5px solid ${
									projectColor || black15
								}`,
								position: "relative",
								overflow: "visible",
							}}
						>
							<ProjectProfileContainer>
								<ProjectLogoContainer
									imageExists={!!projectImageURL}
								>
									{!projectImageUploading &&
										!!projectImageURL && (
											<img
												src={projectImageURL}
												alt="Project logo"
												style={{
													width: "100px",
													height: "100px",
													borderRadius: 4,
													objectFit: "cover",
												}}
											/>
										)}
									{renderProjectImageUploadButton()}
								</ProjectLogoContainer>
								<ProjectProfileDetailsContainer>
									<CommonTextField
										onChange={onProjectNameChange}
										value={localProjectName || ""}
										placeholder="Project name"
										textAlign="center"
										hiddenBorder
										fontSize="19px"
									/>
									<CommonTextField
										style={{ marginBottom: 8 }}
										onChange={onClientNameChange}
										value={localClientName || ""}
										color={black45}
										placeholder="Client name"
										textAlign="center"
										fontSize="15px"
										hiddenBorder
									/>
									<DatePicker
										preText="Delivery date:"
										disableToolbar
										autoOk={true}
										variant="inline"
										format="dd MMM yyyy"
										id="delivery-date"
										label="Delivery date"
										value={localDeliveryDate}
										onChange={onDeliveryDateChange}
									/>
								</ProjectProfileDetailsContainer>
							</ProjectProfileContainer>
							<ProjectColorSelector
								aria-label="Change project color"
								type="color"
								value={localProjectColor || black15}
								onChange={onProjectColorChange}
							/>
						</Card>
						<ActionsContext.Provider
							value={{
								updateActions: updateActions,
								handleActionClick: handleActionClick,
							}}
						>
							<ActionsBlock actions={localActions} />
						</ActionsContext.Provider>
						<MessageBoard
							value={localMessageBoard}
							onChange={handleMessageBoardChange}
						/>
					</Grid>
					<Grid item xs={12} md={8}>
						<Card
							style={{
								width: "100%",
								padding: 24,
								marginBottom: 24,
							}}
						>
							<DayAndRefBar>
								<Grid container spacing={4} alignItems="center">
									<Grid item>
										<DatePicker
											preText="Start:"
											disableToolbar
											autoOk={true}
											variant="inline"
											format="dd MMM yyyy"
											id="start-shoot-date"
											label="Start shoot date"
											value={localStartShootDate}
											onChange={onStartShootDateChange}
										/>
									</Grid>
									<Grid item xs>
										<SliderContainer>
											<CommonTextField
												onChange={
													onShootDateTitleChange
												}
												value={
													localShootDateTitle === null
														? `Day ${currentDaySlider}`
														: localShootDateTitle
												}
												placeholder="Shoot day title"
												textAlign="center"
												hiddenBorder
												fontSize="16px"
											/>

											<Slider
												step={1}
												value={currentDaySlider}
												min={1}
												max={getMaxShootDateSlider()}
												onChange={
													handleCurrentDateSlider
												}
												getAriaValueText={
													getDateSliderText
												}
											/>
											<DaySliderCurrentDetails>
												{`${getDaySliderCurrentDetails}`}
											</DaySliderCurrentDetails>
										</SliderContainer>
									</Grid>
									<Grid item>
										<DatePicker
											preText="End:"
											disableToolbar
											autoOk={true}
											variant="inline"
											format="dd MMM yyyy"
											id="end-shoot-date"
											label="End shoot date"
											value={localEndShootDate}
											minDate={
												new Date(localStartShootDate)
											}
											minDateMessage="End date must be after start date"
											maxDate={getMaxShootEndDate()}
											maxDateMessage="Max 10 shoot days"
											onChange={onEndShootDateChange}
										/>
									</Grid>
									<Grid item>
										<IconButton
											aria-label="More options"
											onClick={onOptionsButtonPress}
											size="large"
										>
											<MoreVert />
										</IconButton>
									</Grid>
								</Grid>
							</DayAndRefBar>
						</Card>

						<Card
							style={{
								minHeight: 346,
								padding: 20,
								marginBottom: 24,
								border: highlightDetailsCard
									? `2px solid ${red}`
									: null,
							}}
							onClick={() => setHighlightDetailsCard(false)}
						>
							<ProjectOverviewContainer>
								<ProjectDetailsContainer>
									<ProjectDetailsInputContainer>
										<TextField
											label="Department"
											style={{ marginBottom: 10 }}
											value={localDepartment || ""}
											onChange={onDepartmentChange}
										/>
										<TextField
											label="Head of Project"
											style={{ marginBottom: 10 }}
											value={localProjectHead || ""}
											onChange={onProjectHeadChange}
										/>
										<TextField
											label="Director"
											style={{ marginBottom: 10 }}
											value={localDirector || ""}
											onChange={onDirectorChange}
										/>
									</ProjectDetailsInputContainer>
									<ProjectDetailsLinksContainer>
										<ProjectDetailLink
											onClick={onLocationButtonPress}
										>
											<LocationOn
												style={{
													marginRight: 5,
													color: `${red}`,
												}}
											/>
											Locations
										</ProjectDetailLink>
										<ProjectDetailLink
											onClick={onDocumentButtonPress}
										>
											<Description
												style={{
													marginRight: 5,
													color: `${blue}`,
												}}
											/>
											Documents
										</ProjectDetailLink>
									</ProjectDetailsLinksContainer>
								</ProjectDetailsContainer>
								<VerticalDivider />
								<ScheduleContainer>
									<ScheduleHeader>Schedule</ScheduleHeader>

									<Scheduler
										data={localSchedule}
										onCellChange={handleScheduleRowChange}
										onAddRowButtonClick={
											handleAddScheduleRowClick
										}
										onDeleteRowClick={
											handleDeleteScheduleRow
										}
									/>
								</ScheduleContainer>
							</ProjectOverviewContainer>
						</Card>

						<Card
							style={{
								minHeight: 346,
								border: highlightContactCard
									? `2px solid ${red}`
									: null,
							}}
							onClick={() => setHighlightContactCard(false)}
						>
							<AppBar position="static" color="default">
								<Tabs
									value={visibleContactTab}
									onChange={onContactsTabChange}
									aria-label="Contacts tab"
									variant="fullWidth"
									scrollButtons="auto"
								>
									<Tab label="Client" />
									<Tab label="Crew" />
									<Tab label="Talent" />
									<Tab label="Supplies" />
									<Tab label="Team" />
								</Tabs>
							</AppBar>
							<ContactTabPanel
								value={visibleContactTab}
								index={0}
							>
								<ContactsTable
									columns={clientContactColumns}
									data={localClientContacts}
									onCellChange={handleClientContactRowChange}
									onAddRowButtonClick={
										handleAddClientContactRowClick
									}
									onDeleteRowClick={
										handleDeleteClientContactRow
									}
								/>
							</ContactTabPanel>
							<ContactTabPanel
								value={visibleContactTab}
								index={1}
							>
								<ContactsTable
									columns={crewContactColumns}
									data={localCrewContacts}
									onCellChange={handleCrewContactRowChange}
									onAddRowButtonClick={
										handleAddCrewContactRowClick
									}
									onDeleteRowClick={
										handleDeleteCrewContactRow
									}
								/>
							</ContactTabPanel>
							<ContactTabPanel
								value={visibleContactTab}
								index={2}
							>
								<ContactsTable
									projectID={projectID}
									columns={talentContactColumns}
									data={localTalentContacts}
									onCellChange={handleTalentContactRowChange}
									onAddRowButtonClick={
										handleAddTalentContactRowClick
									}
									onDeleteRowClick={
										handleDeleteTalentContactRow
									}
								/>
							</ContactTabPanel>
							<ContactTabPanel
								value={visibleContactTab}
								index={3}
							>
								<ContactsTable
									columns={supplierContactColumns}
									data={localSupplierContacts}
									onCellChange={
										handleSupplierContactRowChange
									}
									onAddRowButtonClick={
										handleAddSupplierContactRowClick
									}
									onDeleteRowClick={
										handleDeleteSupplierContactRow
									}
								/>
							</ContactTabPanel>
							<ContactTabPanel
								value={visibleContactTab}
								index={4}
							>
								<ContactsTable
									type="autofill"
									columns={teamContactColumns}
									data={localTeamContacts}
									onCellChange={handleTeamContactRowChange}
									onAddRowButtonClick={
										handleAddTeamContactRowClick
									}
									onDeleteRowClick={
										handleDeleteTeamContactRow
									}
								/>
							</ContactTabPanel>
							<BottomSpacer />
						</Card>
					</Grid>
				</Grid>
			</Box>
			<DropzoneDialog
				dialogTitle="Upload image"
				acceptedFiles={["image/*"]}
				cancelButtonText="cancel"
				submitButtonText="submit"
				maxFileSize={5000000}
				open={showProjectImageDialog}
				filesLimit={1}
				onClose={() => setShowProjectImageDialog(false)}
				onSave={onProjectImageUploaderSave}
				showPreviews={true}
				showFileNamesInPreview={true}
			/>

			{showOptionsPopup && (
				<Popup onClose={onOptionsPopupClose} title="Options">
					<OptionsPopup
						onOptionsPopupClose={onOptionsPopupClose}
						copyDataAcrossAllDays={copyDataAcrossAllDays}
					/>
				</Popup>
			)}

			{showLocationPopup && (
				<Popup onClose={onLocationPopupClose} title="Location">
					<LocationsPopup
						locations={localLocations}
						onLocationChange={onLocationsChange}
					/>
				</Popup>
			)}

			{showDocumentPopup && (
				<DocumentsPopup
					selectedDocuments={localSelectedDocuments}
					onSelectedDocumentsChange={onSelectedDocumentsChange}
					onDocumentPopupClose={onDocumentPopupClose}
					projectID={projectID}
					currentDaySlider={currentDaySlider}
				/>
			)}

			<Snackbar
				open={logoUpdateToastOpen}
				autoHideDuration={2500}
				onClose={handleLogoUpdateClose}
			>
				<Alert onClose={handleLogoUpdateClose} severity="success">
					Logo updated!
				</Alert>
			</Snackbar>
		</Navigation>
	);
};

export default Project;
