import React, { useState } from "react";

import { Toolbar } from "../../Toolbar";
import { SidebarItem } from "./SidebarItem";
import styled from "styled-components";
import { useEditor } from "@craftjs/core";
import { Add } from "@mui/icons-material";

export const SidebarDiv = styled.div<{ enabled: boolean }>`
	position: relative;
	right: 0;
	height: 100%;
	width: ${(props) => (props.enabled ? 315 : 0)}px;
	opacity: ${(props) => (props.enabled ? 1 : 0)};
	background: #fff;
`;

export const Sidebar = () => {
	const [layersVisible, setLayerVisible] = useState(true);
	const [toolbarVisible, setToolbarVisible] = useState(true);
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));

	return (
		<SidebarDiv
			enabled={enabled}
			className="sidebar transition bg-white w-2"
		>
			<div className="flex flex-col h-full">
				<SidebarItem
					title="Customize"
					height={"full"}
					visible={toolbarVisible}
					onChange={(val) => setToolbarVisible(val)}
				>
					<Toolbar />
				</SidebarItem>
			</div>
		</SidebarDiv>
	);
};
