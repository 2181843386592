import React from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { SubtitleBlockSettings } from "./SubtitleBlockSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	shootDateLabel: string;
	shootDate: string;
	projectRefLabel: string;
	projectRef: string;
	margin: [string, string, string, string];
};

export const SubtitleBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	shootDateLabel = "Shoot date:",
	shootDate = "[Shoot date]",
	projectRefLabel = "Reference:",
	projectRef = "[Project reference]",
	margin,
}: Partial<Text>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));
	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column alignItems="right" justifyContent="flex-end">
					<ContentEditable
						innerRef={connect}
						html={shootDateLabel} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) =>
									(prop.shootDateLabel = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: 0,
							fontWeight,
							textAlign: "right",
						}}
					/>
					<ContentEditable
						innerRef={connect}
						html={projectRefLabel} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) =>
									(prop.projectRefLabel = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: 0,
							fontWeight,
							textAlign: "right",
						}}
					/>
				</Column>
				<Column justifyContent="flex-end">
					<ContentEditable
						innerRef={connect}
						html={shootDate} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.shootDate = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: 0,
							fontWeight,
							textAlign: "left",
						}}
					/>
					<ContentEditable
						innerRef={connect}
						html={projectRef} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.projectRef = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: 0,
							fontWeight,
							textAlign: "left",
						}}
					/>
				</Column>
				<Column />
			</ColumnLayout>
		</div>
	);
};

SubtitleBlock.craft = {
	displayName: "Subtitle",
	props: {
		fontSize: "20",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 10, 10],
		shadow: 0,
		shootDateLabel: "Shoot date:",
		shootDate: "[Shoot date]",
		projectRefLabel: "Reference:",
		projectRef: "[Project reference]",
	},
	related: {
		toolbar: SubtitleBlockSettings,
	},
};
