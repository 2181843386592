import styled from "styled-components";
import React, { FC, useMemo, useState } from "react";
import { Alert } from "@mui/lab";
import {
	Button,
	CircularProgress,
	IconButton,
	Snackbar,
	Tooltip,
} from "@mui/material";
import { DropzoneDialog } from "react-mui-dropzone";
import { Add } from "@mui/icons-material";
import { useTable } from "react-table";
import { AddAPhoto } from "@mui/icons-material";
import { fuego } from "@nandorojo/swr-firestore";

import { departments, roles } from "./constants";
import DropdownField from "./components/DropdownField";
import StatusField from "./components/StatusField";
import { TextField } from "components/common";
import {
	black15,
	black45,
	black65,
	black25,
	white,
	black15o20,
	red,
} from "config/colors";

const ContactsTableContainer = styled.div`
	width: 100%;
	padding: 0px 5px;
`;

const TableHeader = styled.th`
	width: ${({ fixedWidth }) => (fixedWidth ? "30px" : "100%")};
	padding: 10px 0 5px;
	border-bottom: 1px solid ${black15};
	border-right: 1px solid ${black15};

	:nth-last-child(2) {
		border-right: 0;
	}
	:last-child {
		width: 22px;
		visibility: hidden;
	}
`;

const TableData = styled.td`
	font-size: 13px;
	margin: 0;
	padding: ${({ padding }) => (padding ? padding : "3px")};
	border-bottom: 1px solid ${black15};
	border-right: 1px solid ${black15};
	width: ${({ fixedWidth }) => (fixedWidth ? "30px" : "100%")};

	:nth-last-child(2) {
		border-right: 0;
	}
	:last-child {
		width: 12px;
		border-right: 0;
		border-bottom: 0;
	}
`;

const CloseButton = styled.button`
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	padding: 1px 5px 1px;
	cursor: pointer;
	color: ${black45};
	border: 0px;
	background-color: transparent;

	&:hover {
		background-color: ${black15};
		border-radius: 50%;
		color: ${red};
	}
`;

const TableRow = styled.tr`
	&:hover {
		background-color: ${black15o20};
	}

	&:hover > :last-child {
		background-color: ${white};
	}

	&:hover ${CloseButton} {
		visibility: visible;
	}
`;

const TalentImageContainer = styled.div`
	position: relative;
	width: 100px;
	height: 100px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	& > button {
		opacity: ${({ imageExists }) => (imageExists ? 0 : 1)};
	}
	&:hover > img {
		opacity: 0.5;
	}
	&:hover > button {
		opacity: 1;
	}

	@media (max-width: 1210px) {
		margin-right: 0;
		margin-bottom: 15px;
	}
`;

// type to temp fix role bug as team will be autofilled later
interface ContactsTableProps {
	type?: string;
	columns: { Header: string; accessor: string }[];
	data: any[];
	onCellChange: (column: string, row: string, text: string) => void;
	onAddRowButtonClick: () => void;
	onDeleteRowClick: (row: number) => void;
	projectID?: string;
}

const ContactsTable: FC<ContactsTableProps> = ({
	type,
	columns,
	data,
	onCellChange,
	onAddRowButtonClick,
	onDeleteRowClick,
	projectID,
}) => {
	const tableColumns = useMemo(
		() => [
			...columns,
			{
				Header: "Delete",
				id: "delete",
				accessor: (str) => "delete",
			},
		],
		[columns]
	);

	const tableData = useMemo(() => data, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns: tableColumns, data: tableData });

	const [showTalentImageDialog, setShowTalentImageDialog] = useState(false);
	const [currentSelectedTalentImage, setCurrentSelectedTalentImage] =
		useState(0);
	const [talentImageURLs, setTalentImageURL] = useState({});
	const [talentImageUploading, setTalentImageUploading] = useState(false);
	const [talentImageUpdateToastOpen, setTalentImageUpdateToastOpen] =
		useState(false);

	const onTalentImageUploadButtonClick = (rowID: number) => () => {
		setCurrentSelectedTalentImage(rowID);
		setShowTalentImageDialog(true);
	};

	const renderProjectImageUploadButton = (rowID: number) =>
		talentImageUploading ? (
			<CircularProgress />
		) : (
			<IconButton
				aria-label="Add a photo"
				style={{
					width: 100,
					height: 100,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: 2,
					border: `1px solid ${black25}`,
					position: "absolute",
				}}
				onClick={onTalentImageUploadButtonClick(rowID)}
				size="large"
			>
				<AddAPhoto />
			</IconButton>
		);

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const onTalentImageUploaderSave = async (files) => {
		setShowTalentImageDialog(true);

		const newImage = files[0];

		const storage = fuego.storage();
		const metadata = {
			contentType: newImage.type,
		};
		const storageRef = await storage.ref();
		const imgFile = storageRef.child(
			`projects/${projectID}/talent/${currentSelectedTalentImage}`
		);
		setShowTalentImageDialog(false);
		try {
			getBase64(newImage, () => {
				setTalentImageUploading(false);
			});
			await imgFile.put(newImage, metadata).then(() => {
				imgFile
					.getDownloadURL()
					.then((pic) => {
						setTalentImageURL({
							...talentImageURLs,
							[currentSelectedTalentImage]: pic,
						});
						onCellChange(
							"image",
							`${currentSelectedTalentImage}`,
							pic
						);
					})
					.catch((error) =>
						console.log("There was an error: ", error)
					);
			});
			setTalentImageUpdateToastOpen(true);
		} catch (e) {}
	};

	const handleTalentImageUpdateClose = () =>
		setTalentImageUpdateToastOpen(false);

	const handleDeleteRowClick = async (rowID: number) => {
		if (talentImageURLs[rowID]) {
			const storage = fuego.storage();
			const storageRef = await storage.ref();
			const imgRef = storageRef.child(
				`projects/${projectID}/talent/${rowID}`
			);
			await imgRef
				.delete()
				.then(() => {})
				.catch((error) => console.log("There was an error: ", error));
			delete talentImageURLs[rowID];
		}
		onDeleteRowClick(rowID);
	};

	const getLocalDepartmentDropdownValue = (values) => {
		if (values?.department !== "-") return values.department;

		if (values?.role !== "-") {
			const roleSectorKey = Object.keys(roles).find((department) =>
				Object.keys(roles[department]).find(
					(role) => role === values.role
				)
			);
			const departmentKey = Object.keys(departments).find(
				(key) => departments[key] === roleSectorKey
			);

			return departmentKey;
		}

		return "-";
	};

	const getLocalRoleDropdownOptions = (values) =>
		values?.department === "-"
			? roles
			: roles[departments[values?.department]];

	return (
		<>
			<ContactsTableContainer>
				<table
					{...getTableProps()}
					style={{
						width: "100%",
						borderSpacing: 0,
						tableLayout: "fixed",
					}}
				>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<TableHeader
										{...column.getHeaderProps()}
										fixedWidth={column.Header === "Status"}
									>
										{column.Header === "Status" ? (
											<></>
										) : (
											column.render("Header")
										)}
									</TableHeader>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row);
							return (
								<TableRow {...row.getRowProps()}>
									{row.cells.map((cell) => {
										if (cell.column.Header === "Image") {
											const imageURL =
												talentImageURLs?.[
													cell.row.id
												] || cell.value;

											return (
												<TableData
													{...cell.getCellProps()}
													fixedWidth
													padding="0px"
												>
													<TalentImageContainer
														imageExists={!!imageURL}
													>
														{!talentImageUploading &&
															!!imageURL && (
																<img
																	src={
																		imageURL
																	}
																	alt="Project logo"
																	style={{
																		width: "100px",
																		height: "100px",
																		borderRadius: 4,
																		objectFit:
																			"cover",
																	}}
																/>
															)}
														{renderProjectImageUploadButton(
															cell.row.id
														)}
													</TalentImageContainer>
												</TableData>
											);
										}
										if (cell.column.Header === "Status") {
											const statusValue =
												cell.value
													.charAt(0)
													.toUpperCase() +
												cell.value.slice(1);

											return (
												<TableData
													{...cell.getCellProps()}
													fixedWidth
													padding="0px"
												>
													<Tooltip
														title={`Status: ${statusValue}`}
														placement="left"
													>
														<div>
															<StatusField
																status={
																	cell.value
																}
																onChange={(
																	statusValue
																) =>
																	onCellChange(
																		cell
																			.column
																			.id,
																		cell.row
																			.id,
																		statusValue
																	)
																}
															/>
														</div>
													</Tooltip>
												</TableData>
											);
										}
										if (
											cell.column.Header === "Department"
										) {
											return (
												<TableData
													{...cell.getCellProps()}
												>
													<div
														style={{
															width: "100%",
														}}
													>
														<DropdownField
															value={getLocalDepartmentDropdownValue(
																cell.row.values
															)}
															options={
																departments
															}
															onChange={(
																option
															) =>
																onCellChange(
																	cell.column
																		.id,
																	cell.row.id,
																	option
																)
															}
														/>
													</div>
												</TableData>
											);
										}
										if (
											cell.column.Header === "Role" &&
											type !== "autofill"
										) {
											return (
												<TableData
													{...cell.getCellProps()}
												>
													<div
														style={{
															width: "100%",
														}}
													>
														<DropdownField
															value={cell.value}
															options={getLocalRoleDropdownOptions(
																cell.row.values
															)}
															onChange={(
																option
															) =>
																onCellChange(
																	cell.column
																		.id,
																	cell.row.id,
																	option
																)
															}
														/>
													</div>
												</TableData>
											);
										}
										if (cell.column.Header !== "Delete") {
											return (
												<TableData
													{...cell.getCellProps()}
												>
													<TextField
														textAlign="center"
														value={cell.value}
														fontSize="13px"
														hiddenBorder
														padding="2px"
														selectOnFocus
														onChange={(text) =>
															onCellChange(
																cell.column.id,
																cell.row.id,
																text
															)
														}
													/>
												</TableData>
											);
										}
										return (
											<TableData {...cell.getCellProps()}>
												<CloseButton
													aria-label={`Delete row ${cell.row.index}`}
													onClick={() =>
														handleDeleteRowClick(
															cell.row.index
														)
													}
												>
													×
												</CloseButton>
											</TableData>
										);
									})}
								</TableRow>
							);
						})}
					</tbody>
				</table>
				<Button
					variant="contained"
					style={{
						width: "100%",
						height: 30,
						marginTop: 4,
						backgroundColor: black15,
					}}
					onClick={onAddRowButtonClick}
				>
					<Add style={{ color: black65 }} />
				</Button>
			</ContactsTableContainer>
			<DropzoneDialog
				dialogTitle="Upload image"
				acceptedFiles={["image/*"]}
				cancelButtonText="cancel"
				submitButtonText="submit"
				maxFileSize={5000000}
				open={showTalentImageDialog}
				filesLimit={1}
				onClose={() => setShowTalentImageDialog(false)}
				onSave={onTalentImageUploaderSave}
				showPreviews={true}
				showFileNamesInPreview={true}
			/>
			<Snackbar
				open={talentImageUpdateToastOpen}
				autoHideDuration={2500}
				onClose={handleTalentImageUpdateClose}
			>
				<Alert
					onClose={handleTalentImageUpdateClose}
					severity="success"
				>
					Talent image updated!
				</Alert>
			</Snackbar>
		</>
	);
};

export default ContactsTable;
