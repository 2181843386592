import styled from "styled-components";
import Link from "next/link";
import React, { FC, useState } from "react";
import Router from "next/router";
import {
	Button,
	IconButton,
	Card,
	CardActionArea,
	Checkbox,
	Tooltip,
} from "@mui/material";
import {
	Description,
	Done,
	Download,
	Edit,
	Send,
	Share,
	Preview,
} from "@mui/icons-material";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";

import { blue, black15, whiteo80, red, white } from "config/colors";
import { DocumentBuilder, EmptyState, Popup } from "components/common";
import { useAuth } from "components/common/HOC/AuthProvider";
import { useCollection } from "@nandorojo/swr-firestore";
import { OrgData } from "pages/profile";
import { DocumentDataProps } from "pages/documents";

const DocumentsPopupContainer = styled.div`
	width: 80vw;
	padding: 24px 12px 24px 24px;
	display: grid;
	overflow-x: visible;
	overflow-y: scroll;
	grid-template-columns: repeat(3, 200px);
	column-gap: 30px;
	row-gap: 20px;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		margin-bottom: 10px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: ${black15};
	}

	scrollbar-color: ${black15} transparent;
	scrollbar-width: thin;
	scrollbar-face-color: ${black15};
	scrollbar-track-color: transparent;
`;

const DocumentTitle = styled.h4`
	width: 140px;
	display: flex;
	align-items: center;
	margin: 0;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const EmptySelectedDocumentSpacer = styled.div`
	height: 30px;
`;

const SeparatorContainer = styled.div`
	padding: 0 24px;
	width: 100%;
	display: grid;
	grid-template-columns: min-content auto;
`;

const SeparatorHeader = styled.span`
	font-weight: bold;
	padding-right: 10px;
	overflow: hidden;
	white-space: nowrap;
`;

const Separator = styled.span`
	width: 100%;
	height: 1px;
	margin: 10px 0;
	background-color: ${black15};
`;

const DocumentBottomSpacer = styled.div`
	height: 12px;
`;

const DocumentPreviewContainer = styled.div`
	position: relative;
`;

const DocumentActionContainer = styled.div`
	position: sticky;
	z-index: 1502;
	top: 0;
	float: right;
	padding: 8px;
	border-bottom-left-radius: 4px;
	background-color: ${whiteo80};
	display: grid;
	grid-template-columns: min-content min-content min-content;
	grid-auto-flow: row;
	column-gap: 12px;
`;

interface DocumentsPopupProps {
	onDocumentPopupClose?: () => void;
	selectedDocuments?: string[] | [];
	onSelectedDocumentsChange?: (selectedDocuments: string[]) => void;
	projectID: string;
	currentDaySlider: number;
	onChange?: (any) => void;
}

const DocumentsPopup: FC<DocumentsPopupProps> = ({
	onDocumentPopupClose,
	selectedDocuments = [],
	onSelectedDocumentsChange,
	projectID,
	currentDaySlider,
	onChange,
}) => {
	const { user }: any = useAuth();

	const [isEditing, setIsEditing] = useState(false);
	const [selectedPreviewDoc, setSelectedPreviewDoc] = useState("");

	// TODO Add back in after user testing
	// const { data: orgData } = useCollection<OrgData>(`organisations`, {
	// 	listen: true,
	// 	where: [
	// 		[
	// 			`participants.${user.id}`,
	// 			"in",
	// 			["super", "owner", "admin", "executive", "organiser"],
	// 		],
	// 	],
	// });
	// where: [[`orgParticipants.${orgData?.[0]?.id}`, "==", true]],

	const { data: documentData } = useCollection<DocumentDataProps>(
		`documents`,
		{
			listen: true,
		}
	);

	const onlySelectedDocuments = documentData
		? documentData.filter((document) =>
				selectedDocuments.includes(document.id)
		  )
		: [];

	const documentDataSortedAlphabettically = documentData
		? [...documentData].sort((a, b) => (a.title > b.title ? 1 : -1))
		: [];

	// 	<Link
	// 	key={key}
	// 	href={
	// 		isEditing
	// 			? ""
	// 			: `/documents/builder/${document.id}?projectID=${projectID}&day=${currentDaySlider}`
	// 	}
	// 	passHref
	// >

	const handleActionButtonPress = () => {
		setIsEditing(!isEditing);
	};

	const renderActionButton = () => (isEditing ? <Done /> : <Edit />);

	const actionDescription = isEditing
		? "Done editing"
		: "Add/remove templates";

	const handleDocPress = (id) => {
		if (isEditing) {
			handleDocumentSelectionChange(id)();
		} else {
			if (selectedPreviewDoc === id) setSelectedPreviewDoc("");
			else setSelectedPreviewDoc(id);
		}
	};

	const isDocumentSelected = (id) => selectedDocuments.includes(id);

	const handleDocumentSelectionChange = (id) => () => {
		const newSelectedDocuments = isDocumentSelected(id)
			? selectedDocuments.filter((docID) => docID !== id)
			: [id, ...selectedDocuments];

		onSelectedDocumentsChange(newSelectedDocuments);
	};

	const handleDocDownloadClick = () => {
		ReactPDF.render(
			<DocumentBuilder
				previewMode
				previewDocID={selectedPreviewDoc}
				previewProjectID={projectID}
				previewCurrentDay={`${currentDaySlider}`}
			/>,
			`docTest.pdf`
		);
	};

	const renderNoDocumentsFound = () => (
		<EmptyState
			icon={<Description style={{ fontSize: 120, fontWeight: 300 }} />}
			title="No documents found"
			actionButtonText="Add a document"
			handleActionButtonClick={() => Router.push("/documents/builder")}
		/>
	);

	const renderSelectedDocuments = () =>
		onlySelectedDocuments &&
		onlySelectedDocuments.map((document, key) => (
			<Card
				key={key}
				style={{
					width: 200,
					height: 50,
				}}
				onClick={() => handleDocPress(document.id)}
			>
				<CardActionArea
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<Description
						style={{
							margin: 10,
							color: `${blue}`,
						}}
					/>
					<Tooltip
						title={document.title}
						placement="bottom"
						enterDelay={500}
					>
						<DocumentTitle>{document.title}</DocumentTitle>
					</Tooltip>
					{isEditing && (
						<Checkbox
							checked={isDocumentSelected(document.id)}
							onChange={handleDocumentSelectionChange(
								document.id
							)}
						/>
					)}
				</CardActionArea>
			</Card>
		));

	const renderSelectedDocumentSection = () => {
		if (onlySelectedDocuments.length) {
			return (
				<DocumentsPopupContainer>
					{renderSelectedDocuments()}
					<DocumentBottomSpacer />
				</DocumentsPopupContainer>
			);
		}

		if (!documentDataSortedAlphabettically.length) {
			return <>{renderNoDocumentsFound()}</>;
		}

		if (!isEditing) {
			return (
				<EmptyState
					icon={
						<Description
							style={{ fontSize: 120, fontWeight: 300 }}
						/>
					}
					title="No documents selected"
					actionButtonText="Select documents"
					handleActionButtonClick={() => setIsEditing(true)}
				/>
			);
		}

		return <EmptySelectedDocumentSpacer />;
	};

	const renderAllDocuments = () =>
		documentDataSortedAlphabettically &&
		documentDataSortedAlphabettically.map((document, key) => (
			<Card
				key={key}
				style={{
					width: 200,
					height: 50,
				}}
				onClick={() => handleDocPress(document.id)}
			>
				<CardActionArea
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<Description
						style={{
							margin: 10,
							color: `${blue}`,
						}}
					/>
					<Tooltip
						title={document.title}
						placement="bottom"
						enterDelay={1000}
						enterNextDelay={1000}
					>
						<DocumentTitle>{document.title}</DocumentTitle>
					</Tooltip>
					<Checkbox
						checked={isDocumentSelected(document.id)}
						onChange={handleDocumentSelectionChange(document.id)}
					/>
				</CardActionArea>
			</Card>
		));

	const renderAllDocumentsSection = () => {
		if (!isEditing) {
			return null;
		}

		return (
			<>
				<SeparatorContainer>
					<SeparatorHeader>All documents</SeparatorHeader>
					<Separator />
				</SeparatorContainer>
				{documentDataSortedAlphabettically.length ? (
					<DocumentsPopupContainer>
						{renderAllDocuments()}
						<DocumentBottomSpacer />
					</DocumentsPopupContainer>
				) : (
					<>{renderNoDocumentsFound()}</>
				)}
			</>
		);
	};

	const renderPreviewSection = () => {
		if (isEditing || !onlySelectedDocuments.length) {
			return null;
		}

		if (!selectedPreviewDoc) {
			return (
				<EmptyState
					icon={
						<Preview style={{ fontSize: 120, fontWeight: 300 }} />
					}
					title="Select a document to preview"
				/>
			);
		}

		return (
			<DocumentPreviewContainer>
				<DocumentActionContainer>
					<Tooltip title="Download PDF" placement="bottom">
						<IconButton
							style={{ backgroundColor: blue, color: white }}
							aria-label={`Download PDF - Coming soon!`}
						>
							<Download />
						</IconButton>
					</Tooltip>
					<Tooltip title="Share PDF" placement="bottom">
						<IconButton
							style={{ backgroundColor: blue, color: white }}
							aria-label={`Share PDF - Coming soon!`}
						>
							<Share />
						</IconButton>
					</Tooltip>
					<Tooltip
						title="Send to SPRK mobile app - Coming soon!"
						placement="bottom"
					>
						<Button
							variant="contained"
							style={{ backgroundColor: blue, color: white }}
							endIcon={<Send />}
						>
							Send
						</Button>
					</Tooltip>
				</DocumentActionContainer>
				<DocumentBuilder
					previewMode
					previewDocID={selectedPreviewDoc}
					previewProjectID={projectID}
					previewCurrentDay={`${currentDaySlider}`}
				/>
			</DocumentPreviewContainer>
		);
	};

	return (
		<Popup
			onClose={onDocumentPopupClose}
			title="Documents"
			padding="0 5px 5px 0"
			actionDescription={actionDescription}
			onActionButtonPress={handleActionButtonPress}
			actionButtonIcon={renderActionButton()}
		>
			{isEditing && (
				<SeparatorContainer>
					<SeparatorHeader>Selected documents</SeparatorHeader>
					<Separator />
				</SeparatorContainer>
			)}
			{renderSelectedDocumentSection()}
			{renderAllDocumentsSection()}
			{renderPreviewSection()}
		</Popup>
	);
};

export default DocumentsPopup;
