import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Divider,
} from "@mui/material";
import { useNode } from "@craftjs/core";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

const SummaryContainer = styled.div`
	padding: 0 24px;
	width: 100%;
`;

const Title = styled.h5`
	font-size: 14px;
	text-align: left;
	font-weight: 500;
`;

const Description = styled.h5`
	font-size: 14px;
	text-align: right;
	font-weight: 500;
`;

const usePanelStyles = makeStyles((_) => ({
	root: {
		background: "transparent",
		boxShadow: "none",
		"&:before": {
			backgroundColor: "rgba(0, 0, 0, 0.05)",
		},
		"&.Mui-expanded": {
			margin: "0 0",
			minHeight: "40px",
			"&:before": {
				opacity: "1",
			},
			"& + .MuiExpansionPanel-root:before ": {
				display: "block",
			},
		},
	},
}));

const useSummaryStyles = makeStyles((_) => ({
	root: {
		"min-height": "36px",
		padding: 0,
	},
	content: {
		margin: "0px",
	},
}));

export const ToolbarSection = ({ title, props, summary, children }: any) => {
	const panelClasses = usePanelStyles({});
	const summaryClasses = useSummaryStyles({});
	const { nodeProps } = useNode((node) => ({
		nodeProps:
			props &&
			props.reduce((res: any, key: any) => {
				res[key] = node.data.props[key] || null;
				return res;
			}, {}),
	}));
	return (
		<Accordion classes={panelClasses}>
			<AccordionSummary classes={summaryClasses}>
				<SummaryContainer>
					<Grid
						container
						direction="row"
						alignItems="center"
						spacing={3}
					>
						<Grid item xs={4}>
							<Title>{title}</Title>
						</Grid>
						{summary && props ? (
							<Grid item xs={8}>
								<Description>
									{summary(
										props.reduce((acc: any, key: any) => {
											acc[key] = nodeProps[key];
											return acc;
										}, {})
									)}
								</Description>
							</Grid>
						) : null}
					</Grid>
				</SummaryContainer>
			</AccordionSummary>
			<AccordionDetails style={{ padding: "0px 24px 20px" }}>
				<Divider />
				<Grid container spacing={1}>
					{children}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};
