import styled from "styled-components";
import React from "react";
import { Paper } from "@mui/material";

import { CardList } from "components/common";
import { ActionProps } from "./types";

const PaperHeader = styled.h3`
	font-size: 18px;
	margin: 20px;
`;

const ActionContainer = styled.div`
	width: 100%;
`;

interface ActionsBlockProps {
	actions?: ActionProps[];
}

function ActionsBlock({ actions }: ActionsBlockProps) {
	return (
		<Paper
			style={{
				maxHeight: 350,
				paddingRight: 6,
				paddingBottom: 24,
				marginBottom: 24,
				overflow: "hidden",
			}}
		>
			<PaperHeader>Actions</PaperHeader>
			<ActionContainer>
				<CardList cardInfo={actions} maxHeight="290px" />
			</ActionContainer>
		</Paper>
	);
}

export default ActionsBlock;
