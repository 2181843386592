import styled from "styled-components";
import React, { FC, useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { black15, black85 } from "config/colors";
import { VideocamOff } from "@mui/icons-material";

const EmptyStateContainer = styled.div`
	width: 100%;
	padding: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Subtitle = styled.h3`
	margin-bottom: 30px;
`;

interface EmptyStateProps {
	icon?: React.ReactNode;
	title?: string;
	actionButtonText?: string;
	handleActionButtonClick?: () => void;
}

const EmptyState: FC<EmptyStateProps> = ({
	icon = <VideocamOff style={{ fontSize: 120, fontWeight: 300 }} />,
	title,
	actionButtonText,
	handleActionButtonClick,
}) => {
	const onActionButtonClick = () => {
		handleActionButtonClick && handleActionButtonClick();
	};
	return (
		<EmptyStateContainer>
			{icon}
			{title && <Subtitle>{title}</Subtitle>}
			{actionButtonText && (
				<Button
					onClick={onActionButtonClick}
					variant="contained"
					color="primary"
				>
					{actionButtonText}
				</Button>
			)}
		</EmptyStateContainer>
	);
};

export default EmptyState;
