import styled from "styled-components";
import React, { ChangeEvent, useState, useContext } from "react";
import { Add, ChevronRight, Remove } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import Router from "next/router";
import Link from "next/link";

import { black600 } from "config/colors";
import { ActionsContextProps, ActionsContext } from "pages/projects/[...id]";
import { ActionProps } from "components/pages/projects/ActionsBlock/types";

const Container = styled.div`
	width: 100%;
	padding: ${({ hasCheckbox }) =>
		hasCheckbox ? "2px 10px 2px 2px" : "10px"};
	display: flex;
	justify-content: ${({ justifyContent }) =>
		justifyContent ? justifyContent : "space-between"};
	align-items: center;
	border: 1px solid ${black600};
	border-radius: 2px;
	cursor: pointer;
	overflow: hidden;

	&:hover {
		box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%),
			0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
		transition: box-shadow 0.3s, border-color 0.3s;
	}

	:disabled {
		opacity: 0.8;
	}
`;

const CardDetail = styled.span`
	width: 100%;
	text-align: left;
	margin: 0 2px 0 7px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const CardLink = styled.a`
	text-decoration: none;
	color: inherit;
`;

const Prefix = styled.span`
	font-weight: bold;
`;

const CounterContainer = styled.div`
	display: flex;
	column-gap: 5px;
`;

function Card({
	detail,
	id,
	isChecked = false,
	action,
	data,
	link,
	viewOnly,
	prefix,
}: ActionProps & typeof viewOnly & typeof prefix) {
	const { updateActions, handleActionClick } =
		useContext<ActionsContextProps>(ActionsContext);
	const [checked, setChecked] = useState(isChecked);

	const renderCardAction = (action, id) => {
		if (action === "add") return <Add />;
		else if (action === "direct" || viewOnly)
			return <ChevronRight onClick={() => handleCardClick(id)} />;
		else if (action === "counter")
			return (
				<CounterContainer>
					<Remove onClick={() => updateActions(id, "data", "-1")} />
					<Add onClick={() => updateActions(id, "data", "+1")} />
				</CounterContainer>
			);
		return <div></div>;
	};

	const replaceDetailWithData = (detail, data) => {
		if (data === undefined) return detail;

		var newDetail = detail;
		Object.keys(data).forEach((key) => {
			newDetail = newDetail.replace(`{${key}}`, data[key]);
		});

		return newDetail;
	};

	const handleCheckClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (!viewOnly) {
			setChecked(event.target.checked);
			updateActions &&
				updateActions(id, "isChecked", event.target.checked);
		}
	};

	const handleCardClick = (id: string) => {
		if (viewOnly && link) Router.push(link);
		else if (handleActionClick && action === "direct")
			handleActionClick(id);
	};

	if (viewOnly) {
		return (
			<Link href={link} passHref>
				<CardLink>
					<Container key={id} hasCheckbox={false}>
						<CardDetail onClick={() => handleCardClick(id)}>
							{prefix ? <Prefix>{`${prefix} - `}</Prefix> : null}
							{replaceDetailWithData(detail, data)}
						</CardDetail>
						{renderCardAction(action, id)}
					</Container>
				</CardLink>
			</Link>
		);
	}

	return (
		<Container key={id} hasCheckbox={action !== undefined && !viewOnly}>
			{action !== undefined && !viewOnly && (
				<Checkbox
					aria-label="Toggle action completion"
					checked={checked}
					onChange={handleCheckClick}
				/>
			)}
			<CardDetail onClick={() => handleCardClick(id)}>
				{prefix ? <Prefix>{`${prefix} - `}</Prefix> : null}
				{replaceDetailWithData(detail, data)}
			</CardDetail>
			{renderCardAction(action, id)}
		</Container>
	);
}

export default Card;
