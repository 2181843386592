import React from "react";
import styled from "styled-components";
import { useNode, useEditor, Canvas } from "@craftjs/core";
import { ProductionBlockSettings } from "./ProductionBlockSettings";
import { Column, ColumnLayout } from "../components";
import ContentEditable from "react-contenteditable";

import { ProjectDetailsProps } from "components/pages/projects/types";

const Spacer = styled.div`
	height: 15px;
`;

export type Text = {
	fontSize: string;
	textAlign: string;
	fontWeight: string;
	color: Record<"r" | "g" | "b" | "a", string>;
	shadow: number;
	productionLabel: string;
	orgName: string;
	orgAddress: any;
	teamContacts: ProjectDetailsProps["teamContacts"];
	margin: [string, string, string, string];
};

export const ProductionBlock = ({
	fontSize,
	textAlign = "center",
	fontWeight,
	color,
	shadow,
	productionLabel = "Production Company",
	orgName = "[Prod Co Name]",
	orgAddress = [
		{ long_name: "[Prod Co ", types: ["street_number"] },
		{ long_name: "Address Line 1]", types: ["route"] },
		{ long_name: "[Prod Co Address Line 2]", types: ["postal_town"] },
		{ long_name: "[Prod Co Address Line 3]", types: ["postal_code"] },
	],
	teamContacts = [
		{
			role: "[Role/Title]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			role: "[Role/Title]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			role: "[Role/Title]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
		{
			role: "[Role/Title]",
			name: "[Name]",
			email: "[E: [Email]]",
			phone: "[P: [Number]]",
		},
	],
	margin,
}: Partial<Text>) => {
	const {
		connectors: { connect },
		setProp,
	} = useNode();
	const { enabled } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));

	const renderAddress = () => {
		const structuredAddress = {
			productionAddress1Value: "",
			productionAddress2Value: "",
			productionAddress3Value: "",
		};

		orgAddress.forEach((item) => {
			if (item?.types.includes("street_number"))
				structuredAddress.productionAddress1Value =
					`${item?.long_name} ${structuredAddress.productionAddress1Value}` ||
					"";
			if (item?.types.includes("route"))
				structuredAddress.productionAddress1Value =
					`${structuredAddress.productionAddress1Value} ${item?.long_name}` ||
					"";
			if (item?.types.includes("postal_town"))
				structuredAddress.productionAddress2Value =
					item?.long_name || "";
			if (item?.types.includes("postal_code"))
				structuredAddress.productionAddress3Value =
					item?.long_name || "";
		});

		return (
			<>
				{Object.keys(structuredAddress).map((key) => (
					<ContentEditable
						key={key}
						innerRef={connect}
						html={structuredAddress[key]} // innerHTML of the editable div
						disabled
						onChange={() => true} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `14px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0",
							fontWeight,
							textAlign: "left",
						}}
					/>
				))}
			</>
		);
	};

	const renderTeamItem = (value, key) => (
		<ContentEditable
			key={key}
			innerRef={connect}
			html={value} // innerHTML of the editable div
			disabled
			onChange={(e) => true} // use true to disable editing
			tagName="h2" // Use a custom HTML tag (uses a div by default)
			style={{
				width: "100%",
				padding: "0 10px",
				color: `rgba(${Object.values(color)})`,
				fontSize: `14px`,
				textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
				fontWeight: "600",
				margin: "0 0 5px 0",
				textAlign: "left",
			}}
		/>
	);

	const renderTeamContacts = () => {
		const renderRoleColumn = () =>
			teamContacts.map((item, key) => renderTeamItem(item.role, key));
		const renderNameColumn = () =>
			teamContacts.map((item, key) => renderTeamItem(item.name, key));
		const renderEmailColumn = () =>
			teamContacts.map((item, key) =>
				renderTeamItem(`E: ${item.email}`, key)
			);
		const renderPhoneColumn = () =>
			teamContacts.map((item, key) =>
				renderTeamItem(`P: ${item.phone}`, key)
			);

		return (
			<>
				<Column alignItems="left">{renderRoleColumn()}</Column>
				<Column alignItems="left">{renderNameColumn()}</Column>
				<Column alignItems="left">{renderEmailColumn()}</Column>
				<Column alignItems="left">{renderPhoneColumn()}</Column>
			</>
		);
	};

	return (
		<div
			ref={connect}
			style={{
				width: `calc(100% - ${margin[1] + margin[3]}px)`,
				margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
			}}
		>
			<ColumnLayout>
				<Column justifyContent="flex-start">
					<ContentEditable
						innerRef={connect}
						html={productionLabel} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) =>
									(prop.productionLabel = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0 0 15px 0",
							fontWeight,
							textAlign: "left",
						}}
					/>
				</Column>
				<Column justifyContent="flex-start">
					<ContentEditable
						innerRef={connect}
						html={orgName} // innerHTML of the editable div
						disabled
						onChange={(e) => {
							setProp(
								(prop) => (prop.orgName = e.target.value),
								500
							);
						}} // use true to disable editing
						tagName="h2" // Use a custom HTML tag (uses a div by default)
						style={{
							width: "100%",
							padding: "0 10px",
							color: `rgba(${Object.values(color)})`,
							fontSize: `${fontSize}px`,
							textShadow: `0px 0px 2px rgba(0,0,0,${
								(shadow || 0) / 100
							})`,
							margin: "0 0 10px 0",
							fontWeight,
							textAlign: "left",
						}}
					/>
					{renderAddress()}
				</Column>
				<Column />
			</ColumnLayout>
			<Spacer />
			<ColumnLayout>{renderTeamContacts()}</ColumnLayout>
		</div>
	);
};

ProductionBlock.craft = {
	displayName: "Production",
	props: {
		fontSize: "20",
		textAlign: "center",
		fontWeight: "500",
		color: { r: 92, g: 90, b: 90, a: 1 },
		margin: [10, 10, 20, 10],
		shadow: 0,
		productionLabel: "Production Company",
		productionValue: "[Prod Co Name]",
		productionAddress1Value: "[Prod Co Address Line 1]",
		productionAddress2Value: "[Prod Co Address Line 2]",
		productionAddress3Value: "[Prod Co Address Line 3]",
		teamContacts: [
			{
				role: "[Role/Title]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
			{
				role: "[Role/Title]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
			{
				role: "[Role/Title]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
			{
				role: "[Role/Title]",
				name: "[Name]",
				email: "[E: [Email]]",
				phone: "[P: [Number]]",
			},
		],
		projectName: "[Project name]",
	},
	related: {
		toolbar: ProductionBlockSettings,
	},
};
