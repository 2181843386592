import styled from "styled-components";
import React from "react";

import Card from "./Card";
import { black15 } from "config/colors";
import { ActionProps } from "components/pages/projects/ActionsBlock/types";

export const CardListContainer = styled.div`
	width: 100%;
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
	padding: 12px 12px 0 12px;
	margin-bottom: 12px;
	display: grid;
	row-gap: 10px;
	overflow-x: visible;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: ${black15};
	}

	scrollbar-color: ${black15} transparent;
	scrollbar-width: thin;
	scrollbar-face-color: ${black15};
	scrollbar-track-color: transparent;
`;

export const CardBottomSpacer = styled.div`
	height: 12px;
`;

interface CardListProps {
	cardInfo: ActionProps[];
	maxHeight?: string;
	viewOnly?: boolean;
	prefix?: string;
}

function CardList({
	cardInfo,
	maxHeight,
	viewOnly = false,
	prefix,
}: CardListProps) {
	const sortedCardInfo = cardInfo.length
		? cardInfo.sort(
				(a, b) => (a?.isChecked ? 1 : 0) - (b?.isChecked ? 1 : 0)
		  )
		: [];

	const filteredCardInfo = cardInfo.length
		? cardInfo.filter((card) => !card.isChecked)
		: [];

	return (
		<CardListContainer maxHeight={maxHeight}>
			{(viewOnly ? filteredCardInfo : sortedCardInfo).map(
				({ detail, id, isChecked, action, link, data }) => (
					<Card
						viewOnly={viewOnly}
						detail={detail}
						id={id}
						isChecked={isChecked}
						action={action}
						data={data}
						link={link}
						key={id}
						prefix={prefix}
					/>
				)
			)}
			<CardBottomSpacer />
		</CardListContainer>
	);
}

export default CardList;
