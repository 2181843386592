import React, { FC, useState, useEffect } from "react";
import cx from "classnames";
import styled from "styled-components";
import { useEditor } from "@craftjs/core";
import { Toolbox } from "./Toolbox";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import {
	Button as MaterialButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { black15 } from "config/colors";

const Container = styled.div`
	display: flex;
	width: ${({ previewMode }) => (previewMode ? "100%" : "100vw")};
	height: ${({ previewMode }) => (previewMode ? "100%" : "100vh")};
	overflow: hidden;
`;

const ContentContainer = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	padding-top: ${({ previewMode }) => (previewMode ? 0 : "60px")};
	background-color: ${black15};
`;

const DocumentRenderer = styled.div`
	height: 100%;
	width: 100%;
	opacity: 1;
	transition-delay: 0.5s;
	overflow: ${({ enabled }) => (enabled ? "auto" : "")};
`;

const DocumentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 40px 20px 20px;
	overflow: auto;
`;

interface ViewPortProps {
	previewMode?: boolean;
	previewDocID?: string;
	previewProjectID?: string;
	previewCurrentDay?: string;
	children?: any;
}

export const Viewport: FC<ViewPortProps> = ({
	previewMode,
	previewDocID,
	previewProjectID,
	previewCurrentDay,
	children,
}) => {
	const { enabled, connectors, activeNode } = useEditor((state) => ({
		enabled: state.options.enabled,
		related:
			state.events.selected && state.nodes[state.events.selected].related,
		activeNode: state.nodes[state.events.selected],
	}));
	const [loaded, setLoaded] = useState(false);
	const [mouseEnabled, setMouseEnabled] = useState(false);

	let unmounted = false;
	// animations with setTimeouts. I know, don't judge me! :p
	useEffect(() => {
		setTimeout(() => {
			if (!unmounted) setLoaded(true);
			setTimeout(() => {
				if (
					localStorage &&
					localStorage.getItem("craftjs-demo-notice") != "set"
				) {
					localStorage.setItem("craftjs-demo-notice", "set");
				}
				setTimeout(() => {
					if (!unmounted) setMouseEnabled(true);
				}, 200);
			}, 400);
		}, 1000);

		return () => {
			unmounted = true;
		};
	}, []);

	const shouldShowSidebar =
		activeNode?.data?.displayName === "Note" && !previewMode;

	return (
		<Container previewMode={previewMode}>
			<Header
				previewMode={previewMode}
				previewDocID={previewDocID}
				previewProjectID={previewProjectID}
				previewCurrentDay={previewCurrentDay}
			/>

			<ContentContainer enabled={enabled} previewMode={previewMode}>
				{!previewMode && <Toolbox />}
				<DocumentRenderer
					className="craftjs-renderer"
					ref={(ref) =>
						connectors.select(connectors.hover(ref, null), null)
					}
				>
					<DocumentContainer>{children}</DocumentContainer>
				</DocumentRenderer>
				{shouldShowSidebar && <Sidebar />}
			</ContentContainer>
		</Container>
	);
};
