import * as React from "react";
import { UserComponent, useNode, Node } from "@craftjs/core";

const ColumnLayout: UserComponent<{}> = ({ children }) => {
	const { connectors } = useNode();

	return (
		<div
			id="ColumnLayout"
			ref={connectors ? connectors.connect : null}
			style={{
				width: "100%",
				display: "grid",
				gridAutoFlow: "column",
				gridTemplateColumns: "repeat(auto-fit, minmax(10px, 1fr)",
			}}
		>
			{children}
		</div>
	);
};

ColumnLayout.craft = {
	name: "ColumnLayout",
	rules: {
		canMoveIn: (selectedNode: Node) => {
			return selectedNode.data.displayName === "Column";
		},
	},
};

export default ColumnLayout;
